

export enum CustomerCategoryOld {
    Restaurant = "Restaurant",
    FastFood = "FastFood",
    Shop = "Shop",
    Bar = "Bar",
    Catering = "Catering"
}

export enum CustomerCategory {
    Restaurant = "Restaurant",
    FastFood = "FastFood",
    Shop = "Shop",
    //Bar = "Bar",
    //Catering = "Catering"
}


export class CustomerCategoryHelper {
    static getName(category: CustomerCategory, i18n: { locale: string; t: (key: string) => string }): string {
        switch(category) {
            case CustomerCategory.Restaurant: return i18n.t('categories.restaurant')
            case CustomerCategory.FastFood: return i18n.t('categories.fastFood')
            case CustomerCategory.Shop: return i18n.t('categories.shop')
        }
    }
}