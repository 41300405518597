import {
  AutoEncoder,
  field,
  IntegerDecoder,
} from "@simonbackx/simple-encoding";

import { File } from "./File";

export class Resolution extends AutoEncoder {
  @field({ decoder: File })
  file: File;

  @field({ decoder: IntegerDecoder })
  width: number;

  @field({ decoder: IntegerDecoder })
  height: number;
}
