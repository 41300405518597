import { Session } from '@restofrit/networking';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Decoder } from '@simonbackx/simple-encoding';
import { Language, User } from '@restofrit/structures';
import { EditLanguageHelper } from '../../frontend/app/dashboard/src/classes/EditLanguageHelper';

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: "nl", 
    fallbackLocale: 'en',
    messages: {} 
});

const supportedLanguages = ["nl", "fr", "en"]
let translations = {};

async function loadLanguage(lang: string): Promise<boolean> {
    // split locale into language
    lang = lang.substr(0, 2);

    if (!supportedLanguages.includes(lang)) {
        lang = "en"
    }

    // language already loaded, skip importing
    if (Object.keys(translations).includes(lang)) return true;
    
    console.log("Downloading locale "+lang)

    // language not loaded yet, import from json and store in translations
    const loaded = await import(/* webpackChunkName: "lang-[request]" */ `@restofrit/i18n/locales/${lang}.json`)
    if (loaded) {
        translations = { ...translations, [lang]: loaded.default }
        i18n.setLocaleMessage(lang, loaded.default)
        return true;
    }
    return false;
}


export async function switchLanguage(locale: string, isUserEdit: boolean = true) {
    if (i18n.locale == locale) return;

    if(isUserEdit) {
        Session.shared.authenticatedServer
            .request({
                method: "PATCH",
                path: "/auth/user",
                body: { language: locale },
                decoder: User as Decoder<User>,
            })
            .then((result) => {
                switch (result.data.language) {
                    case "en":
                        EditLanguageHelper.language = Language.en;
                        break;
                    case "fr":
                        EditLanguageHelper.language = Language.fr;
                        break;
                    default:
                        EditLanguageHelper.language = Language.nl;
                        break;
                }
                console.log({switchLanguageResponse: result.data});
            })
            .catch(() => {
                console.error("Failed to change profile");
            });
    }

    await loadLanguage(locale)
    i18n.locale = locale.substr(0, 2);
}

// Load the current browser language
export async function load() {
    // Get the language from the OS
    let locale = window.navigator.languages ? window.navigator.languages[0] : null;
    locale = Session.shared.user?.language ? Session.shared.user.language : locale;
    locale = locale || window.navigator.language || (window.navigator as any).browserLanguage || (window.navigator as any).userLanguage;

    if (!locale) {
        // Default to Dutch
        locale = "en"
    }


    const lang = locale.substr(0, 2);

    if (!supportedLanguages.includes(lang)) {
        // Fallback to default
        locale = "en"
    }
    i18n.locale = lang

    await loadLanguage(locale);
}