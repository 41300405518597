<template>
    <div class="segmented-control">
        <div v-for="(item, index) of items" :key="index" class="item" :class="{ selected: isSelected(index) }" @click="selectItem(index)">
            <span>{{ getLabel(index) }}</span>
            <span v-if="getBadge(index)" class="badge" v-text="getBadge(index)" />
        </div>
    </div>
</template>


<script lang="ts">
import { Component, Prop,Vue } from "vue-property-decorator";

@Component({
    
})
export default class SegmentedControl extends Vue {
    @Prop({ required: true })
    items!: any[];

    @Prop({ default: null })
    labels!: string[] | null;

    @Prop({ default: null })
    badges!: string[] | null;

    @Prop({})
    value!: any;

    getLabel(index: number) {
        if (this.labels === null) {
            return this.items[index].toString()
        }
        return this.labels[index]
    }

    getBadge(index: number) {
        if (this.badges === null) {
            return null
        }
        return this.badges[index]
    }

    isSelected(index: number) {
        return this.value === this.items[index]
    }

    selectItem(index: any) {
        this.$emit('input', this.items[index])
    }
}
</script>

<style lang="scss">
@use '~@restofrit/scss/base/variables' as *;
@use '~@restofrit/scss/base/text-styles' as *;

.segmented-control {
    width: 100%;
    display: flex;
    padding-bottom: 15px;
    overflow: hidden;

    .item {
        text-align: center;
        border-bottom: 2px solid $color-border;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;
        padding: 8px 5px;
        touch-action: manipulation;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        user-select: none;
        cursor: pointer;
        color: inherit;
        @extend .style-h4;

        @media (max-width: 400px) {
            font-size: 12px;
        }

        &.selected {
            border-bottom: 2px solid $color-primary;
            color: $color-primary;
        }

        span {
            vertical-align: middle;
        }

        .badge {
            background: $color-primary;
            display: inline-block;
            padding: 0px 5px;
            line-break: 1;
            box-sizing: border-box;
            text-align: center;
            color: $color-white;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            border-radius: 5px;
        }
    }
}
</style>