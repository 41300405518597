<template>
    <div class="rf-view login-view">
        <form class="center view" @submit.prevent="submit">
            <img class="logo" src="@restofrit/assets/images/logo/resto-frit.png">
            <main>
                <h1>
                    {{ $t('buttons.login') }}
                </h1>

                <GeneralErrorsView :error-box="errorBox" />

                <InputBox :title="$t('fields.email')" :error-box="errorBox" error-fields="email">
                    <input v-model="email" class="input" :placeholder="$t('fields.placeholders.email')" autocomplete="username" type="email" autofocus>
                </InputBox>

                <InputBox :title="$t('fields.password')" :error-box="errorBox" error-fields="password">
                    <input v-model="password" class="input" :placeholder="$t('fields.placeholders.password')" autocomplete="current-password" type="password">
                </InputBox>
            </main>

            <FloatingFooter>
                <LoadingButton :loading="loading" class="block">
                    <button class="button primary full">
                        {{ $t('buttons.login') }}
                    </button>
                </LoadingButton>
                <button class="button secundary full" type="button" @click="forgot">
                    {{ $t('buttons.forgotPassword') }}
                </button>
            </FloatingFooter>
        </form>
    </div>
</template>


<script lang="ts">
import { FloatingFooter, ForgotPasswordView,InputBox, LoadingButton, ResetForgotPasswordView } from "@restofrit/components"
import { NetworkManager, Session } from "@restofrit/networking";
import { PasswordGrantStruct, Token } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter
    },
})
export default class LoginView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    password = ""
    loading = false

    mounted() {
        const parts = window.location.pathname.substring(1).split("/");
        if (parts.length == 1 && parts[0] == "reset-password") {
            this.present(new ComponentWithProperties(ResetForgotPasswordView).setDisplayStyle("popup").setAnimated(false))
        }
    }

    async submit() {
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            const response = await NetworkManager.server.request({
                method: "POST",
                path: "/auth/token",
                body: new PasswordGrantStruct({
                    username: this.email,
                    password: this.password
                }),
                decoder: Token
            })
            await Session.shared.setToken(response.data)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    forgot() {
        this.present(new ComponentWithProperties(ForgotPasswordView).setDisplayStyle("side-view"))
    }
    
}
</script>

<style lang="scss">
.login-view {

    .logo {
        height: 100px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    > .center {
        max-width: 100%;
        margin: 0 auto;
        width: 400px;
    }
}
</style>