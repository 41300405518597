import { Promotion, PromotionStats } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities"
import VueI18n from "vue-i18n";
import * as XLSX from "xlsx";

function createOrdersSheet(promotion: Promotion, stats: PromotionStats, i18n: VueI18n, includeWholesaler = true) {
    /* make worksheet */
    const data: any[][] = [
        [
            /*i18n.t('promotion.statistics.columns.promotion'),
            i18n.t('promotion.statistics.columns.articleNumber'), // todo*/
            i18n.t("promotion.statistics.columns.wholesaler"),
            i18n.t("promotion.statistics.columns.amount"),
            i18n.t("promotion.statistics.columns.date"),
            i18n.t("promotion.statistics.columns.internalClientName"),
            i18n.t("promotion.statistics.columns.customerNumber"),
            i18n.t("promotion.statistics.columns.businessName"),
            i18n.t("fields.city"),
            i18n.t("fields.postalCode"),
            i18n.t("fields.address"),
            i18n.t("promotion.statistics.columns.email"),
            i18n.t("promotion.statistics.columns.contactName"),
            i18n.t("promotion.statistics.columns.phone"),
            i18n.t("promotion.statistics.columns.VATNumber"),
        ],
    ];


    for (const order of stats.orders) {
        const wholesaler = stats.wholesalers.find(w => w.id === order.customer.wholesalerId)
        if (!wholesaler) {
            throw new Error("Wholesaler not found")
        }

        data.push([
            /*order.settings.promotion.settings.title.get(wholesaler.settings.language),
            "", // Artikelnummer (not implemented yet)*/
            wholesaler.settings.name,
            order.settings.amount+"",
            order.createdAt,
            order.customer.settings.internalClientName,
            order.customer.settings.number,
            order.customer.settings.businessName,
            order.customer.settings.city,
            order.customer.settings.postalCode,
            order.customer.settings.address,
            order.customer.user.email,
            order.customer.settings.contactName,
            order.customer.settings.contactPhone,
            order.customer.settings.VATNumber,
        ])
    }

    // Delete first column if only one wholesaler
    if (!includeWholesaler) { 
        for (const row of data) {
            row.splice(0, 1)
        } 
    }

    // Delete empty columns
    for (let index = data[0].length - 1; index >= 0; index--) {
        let empty = true
        for (const row of data.slice(1)) {
            if (row[index].length == 0 || row[index] == "/") {
                continue;
            }
            empty = false
            break
        }
        if (empty) {
            for (const row of data) {
                row.splice(index, 1)
            } 
        }
    }

    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column width
    ws['!cols'] = []
    for (const column of data[0]) {
        if (column.toLowerCase().includes("naam")) {
            ws['!cols'].push({width: 15});
        } else if (column.toLowerCase().includes("mail")) {
            ws['!cols'].push({width: 25});
        } else {
            ws['!cols'].push({width: 13});
        }
    }

    return ws

}

function createViewsSheet(promotion: Promotion, stats: PromotionStats, i18n: VueI18n, includeWholesaler = true) {
    /* make worksheet */
    const data: any[][] = [
        [
            /*i18n.t('promotion.statistics.columns.promotion'),
            i18n.t('promotion.statistics.columns.articleNumber'), // todo*/
            i18n.t("promotion.statistics.columns.wholesaler"),
            i18n.t("promotion.statistics.columns.viewDate"),
            i18n.t("promotion.statistics.columns.internalClientName"),
            i18n.t("promotion.statistics.columns.customerNumber"),
            i18n.t("promotion.statistics.columns.businessName"),
            i18n.t("fields.city"),
            i18n.t("fields.postalCode"),
            i18n.t("fields.address"),
            i18n.t("promotion.statistics.columns.email"),
            i18n.t("promotion.statistics.columns.contactName"),
            i18n.t("promotion.statistics.columns.phone"),
            i18n.t("promotion.statistics.columns.VATNumber"),
        ],
    ];


    for (const view of stats.views) {
        const wholesaler = stats.wholesalers.find(w => w.id === view.customer.wholesalerId)
        if (!wholesaler) {
            throw new Error("Wholesaler not found")
        }

        data.push([
            wholesaler.settings.name,
            view.createdAt,
            view.customer.settings.internalClientName,
            view.customer.settings.number,
            view.customer.settings.businessName,
            view.customer.settings.city,
            view.customer.settings.postalCode,
            view.customer.settings.address,
            view.customer.user.email,
            view.customer.settings.contactName,
            view.customer.settings.contactPhone,
            view.customer.settings.VATNumber,
        ]);
    }

    // Delete first column if only one wholesaler
    if (!includeWholesaler) { 
        for (const row of data) {
            row.splice(0, 1)
        } 
    }

    // Delete empty columns
    for (let index = data[0].length - 1; index >= 0; index--) {
        let empty = true
        for (const row of data.slice(1)) {
            if (row[index].length == 0 || row[index] == "/") {
                continue;
            }
            empty = false
            break
        }
        if (empty) {
            for (const row of data) {
                row.splice(index, 1)
            } 
        }
    }

    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column width
    ws['!cols'] = []
    for (const column of data[0]) {
        if (column.toLowerCase().includes("naam")) {
            ws['!cols'].push({width: 15});
        } else if (column.toLowerCase().includes("mail")) {
            ws['!cols'].push({width: 25});
        } else {
            ws['!cols'].push({width: 13});
        }
    }

    return ws

}

export function exportStats(promotion: Promotion, stats: PromotionStats, i18n: VueI18n, includeWholesaler = true) {
    const wb = XLSX.utils.book_new();

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, createOrdersSheet(promotion, stats, i18n, includeWholesaler), i18n.t('promotion.statistics.orderSheetName').toString());
    XLSX.utils.book_append_sheet(wb, createViewsSheet(promotion, stats, i18n, includeWholesaler), i18n.t('promotion.statistics.viewsSheetName').toString());
    XLSX.writeFile(wb, Formatter.slug(i18n.t('promotion.statistics.excelFileName').toString())+".xlsx");
}