<template>
    <div class="rf-view background">
        <NavigationBar :title=" $t('promotion.statistics.title') ">
            <BackButton v-if="canPop" slot="left" @click="pop" />
            <button v-else-if="canDismiss" slot="right" class="button icon close" type="button" @click="dismiss" />
        </NavigationBar>
        <main>
            <h1>{{ $t('promotion.statistics.title') }}</h1>

            <GeneralErrorsView :error-box="errorBox" />

            <Spinner v-if="loading" />
            <template v-else-if="stats">
                <SplitForm>
                    <div slot="left" class="container">
                        <InputBox :title="$t('promotion.statistics.viewedByNumberOfCustomers')">
                            {{ stats.views.length }}
                        </InputBox>
                    </div>
                    <template slot="right">
                        <InputBox :title="$t('promotion.statistics.numberOfOrders')">
                            {{ stats.orders.length }}
                        </InputBox>
                    </template>
                </SplitForm>

                <template v-if="stats.views.length > 0">
                    <hr>
                    <h2>{{ $t('promotion.statistics.viewedByTitle') }}</h2>

                    <List>
                        <ListItem v-for="view in stats.views" :key="view.customer.id">
                            <h3 class="style-h4">
                                {{ view.customer.settings.businessName }}
                            </h3>
                            <p v-if="isAdmin">
                                {{ getWholesaler(view.customer).settings.name }}
                            </p>
                            <template slot="right">
                                {{ view.createdAt | date }}
                            </template>
                        </ListItem>
                    </List>
                </template>

                <template v-if="stats.orders.length > 0">
                    <hr>
                    <h2>{{ $t('promotion.statistics.orderdByTitle') }}</h2>

                    <List>
                        <ListItem v-for="order in stats.orders" :key="order.id">
                            <h3 class="style-h4">
                                {{ order.customer.settings.businessName }}
                            </h3>
                            <p v-if="isAdmin">
                                {{ getWholesaler(order.customer).settings.name }}
                            </p>
                            <p>
                                x {{ order.settings.amount }}
                            </p>
                            <template slot="right">
                                {{ order.createdAt | date }}
                            </template>
                        </ListItem>
                    </List>
                </template>
            </template>
        </main>

        <Toolbar>
            <button type="button" class="button secundary" @click="downloadExcel">
                {{ $t('promotion.statistics.downloadExcelButton') }}
            </button>
        </Toolbar>
    </div>
</template>

<script lang="ts">
import { BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput, PromotionBox, Spinner, SplitForm, TimeInput, Toolbar, UploadImageButton } from "@restofrit/components";
import { Session } from "@restofrit/networking";
import { CustomerPrivate, Promotion, PromotionStats, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities";
import { Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { exportStats } from "./PromotionExcelExporter";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        BackButton,
        Checkbox,
        TimeInput,
        NumberInput,
        SplitForm,
        PromotionBox,
        UploadImageButton,
        Spinner,
        Toolbar
    },
    filters: {
        date: Formatter.dateTime.bind(Formatter)
    }
})
export default class PromotionStatsView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: null })
    wholesaler!: WholesalerPrivate | null

    loading = true

    errorBox: ErrorBox | null = null
    stats: PromotionStats | null = null

    created() {
        this.reload().catch(console.error)
    }

    get isAdmin() {
        return Session.shared.user?.permissions?.isAdmin ?? false
    }

    getWholesaler(customer: CustomerPrivate) {
        return this.stats?.wholesalers.find(wholesaler => wholesaler.id == customer.wholesalerId) ?? null
    }

    async reload() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path:  "/dashboard/promotions/"+this.promotion.id+"/stats",
                decoder: PromotionStats as Decoder<PromotionStats>
            })
            this.stats = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }
    
    downloadExcel() {
        if (!this.stats) {
            return
        }
        exportStats(this.promotion, this.stats, this.$i18n, this.isAdmin)
    }
}
</script>