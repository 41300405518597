<template>
    <div class="rf-view background">
        <NavigationBar :title="viewTitle">
            <BackButton v-if="canPop || canDismiss" slot="left" @click="pop" />
        </NavigationBar>
        <main>
            <h1>{{ viewTitle }}</h1>

            <GeneralErrorsView :error-box="errorBox" />

            <TimeInput v-if="!patchedNotification.didSend" v-model="date" :title="$t('fields.notificationAt')"
                       :error-box="errorBox" error-fields="date" :with-date="true"
            />
            
            <InputBox :title="$t('fields.notificationTitle')" :error-box="errorBox" error-fields="title">
                <input v-model="title" class="input"
                       :placeholder="patchedNotification.usedNotificationTitle(promotion.settings).get(language)"
                >
            </InputBox>

            <p class="style-description">
                {{ $t('editPromotion.notificationTitleDescription') }}
            </p>
            
            <InputBox :title="$t('fields.notificationDescription')" :error-box="errorBox" error-fields="description">
                <input v-model="description" class="input"
                       :placeholder="patchedNotification.usedNotificationDescription(promotion.settings).get(language)"
                >
            </InputBox>

            <Checkbox v-model="ifNotRead">
                {{ $t('fields.notificationIfNotRead') }}
            </Checkbox>

            <template v-if="patchedNotification.didSend">
                <hr>
                <p class="style-description">
                    {{ $t('editPromotion.notificationsSendDescription') }}
                </p>
                <button type="button" class="button destructive" @click="resendNotification">
                    {{ $t('editPromotion.notificationResendButton') }}
                </button>
            </template>
            <template v-else-if="notification.didSend">
                <hr>
                <p>{{ $t('editPromotion.notificationResendDescription') }}</p>
            </template>
            
            <template v-if="!isNew">
                <hr>

                <button type="button" class="button destructive" @click="deleteMe">
                    {{ $t('buttons.delete') }}
                </button>
            </template>
        </main>

        <Toolbar>
            <LoadingButton :loading="saving">
                <button class="primary button" @click="save">
                    {{ $t('buttons.done') }}
                </button>
            </LoadingButton>
        </Toolbar>
    </div>
</template>


<script lang="ts">
import { BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput, PromotionBox, Spinner, SplitForm, TimeInput, Toolbar, UploadImageButton } from "@restofrit/components";
import { Notification, Promotion, TranslatedString, Version } from "@restofrit/structures";
import { AutoEncoderPatchType, PartialWithoutMethods, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { SimpleError } from '@simonbackx/simple-errors';
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import {EditPromotionLanguageHelper } from "../../classes/EditLanguageHelper";
import EditLanguageSelection from "../components/EditLanguageSelection.vue";


@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        Checkbox,
        TimeInput,
        NumberInput,
        SplitForm,
        PromotionBox,
        UploadImageButton,
        EditLanguageSelection,
        Spinner
    },
})
export default class NotificationEditView extends Mixins(NavigationMixin) {
    /**
        * Pass the wholesaler that you are going to edit (or are going to create)
        */
    @Prop({ required: true })
    notification!: Notification

    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: false })
    isNew!: boolean

    copyTagAdded = false;

    patch: AutoEncoderPatchType<Notification> = Notification.patch({})

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<Notification>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    EditPromotionLanguageHelper = EditPromotionLanguageHelper

    get language() {
        return EditPromotionLanguageHelper.language
    }

    get viewTitle() {
        if (this.isNew) {
            if(this.patchedNotification.title.getIfExists(EditPromotionLanguageHelper.language) && !this.copyTagAdded) {
                this.copyTagAdded = true;
                return this.$t('editNotification.newNotificationTitle') + this.$t('editPromotion.copyTag').toString();
            } else {
                return this.$t('editNotification.newNotificationTitle');
            }
        }
        return this.$t('editNotification.editNotificationTitle');
    }

    get patchedNotification() {
        return this.notification.patch(this.patch)
    }

    applyPatch(patch: PartialWithoutMethods<AutoEncoderPatchType<Notification>>) {
        this.patch = this.patch.patch(Notification.patch(patch))
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.notification, { version: Version })
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }

    resendNotification() {
        this.applyPatch({ didSend: false })
    }

    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            // Check if notification date is past current time
            const currentTime = new Date();
            if (this.patchedNotification.date < currentTime) {
                throw new SimpleError({
                    code: "invalid_date",
                    message: this.$t('errors.pastNotificationDate').toString(),
                    field: "date"
                });
            }

            const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
            this.patch.id = this.notification.id
            arr.addPatch(this.patch)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    async deleteMe() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
            arr.addDelete(this.notification.id)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }


    // Fields
    get date() {
        return this.patchedNotification.date
    }

    set date(date: Date) {
        this.applyPatch({ date })
    }

    get ifNotRead() {
        return this.patchedNotification.ifNotRead
    }

    set ifNotRead(ifNotRead: boolean) {
        this.applyPatch({ ifNotRead })
    }

    get title() {
        return this.patchedNotification.title.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set title(title: string) {
        this.applyPatch({ title: TranslatedString.createPatch(title ? title : null, EditPromotionLanguageHelper.language) })
    }

    get description() {
        return this.patchedNotification.description.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set description(description: string) {
        this.applyPatch({ description: TranslatedString.createPatch(description ? description : null, EditPromotionLanguageHelper.language) })
    }
}
</script>