<template>
    <!-- Div is needed to fix vue bug -->
    <div>
        <ComponentWithPropertiesInstance v-if="myRootComponent" :key="myRootComponent.key" :component="myRootComponent" />
        <LoadingView v-else />
    </div>
</template>

<script lang="ts">
import { ComponentWithProperties, ComponentWithPropertiesInstance,NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins,Prop } from "vue-property-decorator";

import LoadingView from "./LoadingView.vue"

@Component({
    components: {
        ComponentWithPropertiesInstance,
        LoadingView
    },
})
export default class PromiseView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    promise: () => Promise<ComponentWithProperties>

    myRootComponent: ComponentWithProperties | null = null

    created() {
        this.reload()
    }

    run() {
        console.log("Promise run")
        this.promise.call(this).then((value) => {
           this.myRootComponent = value
           this.$forceUpdate();  
        }).catch(e => {
            console.error(e)
            console.error("Promise error not caught, defaulting to dismiss behaviour in PromiseView")
            this.dismiss({ force: true });
        })
    }

    reload() {
        this.myRootComponent = null;
        this.run();
    }

    async shouldNavigateAway(): Promise<boolean> {
        if (!this.myRootComponent) {
            return true;
        }
        return await (this.myRootComponent.shouldNavigateAway());
    }
}
</script>