<template>
    <ContextMenu v-bind="{ x, y }">
        <ListItem v-for="language in languages" :key="language" element-name="label" :selectable="true">
            {{ getLanguageName(language) }}
            <Radio v-model="currentLanguage" slot="right" :value="language" />
        </ListItem>
    </ContextMenu>
</template>

<script lang="ts">
import { ContextMenu, ListItem, Radio } from "@restofrit/components";
import { Language, LanguageHelper } from "@restofrit/structures"
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins,Prop } from "vue-property-decorator";
import { EditLanguageHelper, EditPromotionLanguageHelper } from "../../classes/EditLanguageHelper";


@Component({
    components: {
        ContextMenu,
        ListItem,
        Radio
    }
})
export default class LanguageSelectionContextMenu extends Mixins(NavigationMixin) {
    @Prop({ default: false, type: Boolean })
    isPromotionLanguage!: boolean;

    EditLanguageHelper = EditLanguageHelper;
    EditPromotionLanguageHelper = EditPromotionLanguageHelper;

    @Prop({ default: 0 })
    x!: number;

    @Prop({ default: 0 })
    y!: number;

    @Prop()
    setLanguage!: (language: Language) => void;


    get languages() {
        return Object.values(Language)
    }

    get currentLanguage() {
        if (this.isPromotionLanguage) {
            return EditPromotionLanguageHelper.language
        }
        return EditLanguageHelper.language
    }

    set currentLanguage(lang: Language) {
        this.setLanguage(lang)
        this.pop({ force: true })
    }

    isActive(lang) {
        return this.currentLanguage == lang
    }

    getLanguageName(language: Language) {
        return LanguageHelper.getName(language)
    }
}
</script>