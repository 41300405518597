<template>
    <div>
        <label class="radio">
            <input v-model="radioButtonValue" type="radio" :name="name" :value="value" :disabled="disabled">
            <div>
                <div />
                <div><slot /></div>
            </div>
        </label>
    </div>
</template>

<script lang="ts">
import { Component, Prop,Vue } from "vue-property-decorator";

@Component({
    "model": {
        "prop": "model",
        "event": "change"
    },
})
export default class Radio extends Vue {
    @Prop({ default: "", type: String })
    name!: string;

    @Prop({ default: "" })
    value!: any;

    @Prop({})
    model!: any;

    @Prop({ default: false })
    disabled!: boolean;

    get radioButtonValue() {
        return this.model;
    }

    set radioButtonValue(value) {
        this.$emit("change", value)
    }
}
</script>
