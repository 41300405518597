import {
  AutoEncoder,
  field,
  IntegerDecoder,
  StringDecoder,
} from "@simonbackx/simple-encoding";
import { v4 as uuidv4 } from "uuid";

export class File extends AutoEncoder {
  @field({ decoder: StringDecoder, defaultValue: () => uuidv4() })
  id: string;

  /**
   * Server address, including protocol. e.g. https://files.restofrit.be
   */
  @field({ decoder: StringDecoder })
  server: string;

  /// Path relative to the server
  @field({ decoder: StringDecoder })
  path: string;

  /// file size in bytes
  @field({ decoder: IntegerDecoder })
  size: number;

  getPublicPath(): string {
    return this.server + "/" + this.path;
  }
}
