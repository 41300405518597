import { AutoEncoder, EnumDecoder, field,StringDecoder } from '@simonbackx/simple-encoding';
import { v4 as uuidv4 } from "uuid";

import { Image } from './files/Image';
import { Language } from './Language';

export class WholesalerPrivateSettings extends AutoEncoder {
    /**
     * E-mail to receive orders on
     */
    @field({ decoder: StringDecoder, version: 6 })
    email = ""
}

export class WholesalerColors extends AutoEncoder {
    @field({ decoder: StringDecoder })
    primary = "#396C33"

    @field({ decoder: StringDecoder })
    dark = "#340808"
}

export class WholesalerSettings extends AutoEncoder {
    @field({ decoder: StringDecoder })
    name = ""

    @field({ decoder: new EnumDecoder(Language), version: 12 })
    language = Language.nl

    @field({ decoder: WholesalerColors })
    colors = WholesalerColors.create({})

    @field({ decoder: Image, nullable: true, version: 5 })
    logo: Image | null = null
}

/**
 * Wholesaler data that is visible for the customer
 */
export class Wholesaler extends AutoEncoder {
    @field({ decoder: StringDecoder, defaultValue: () => uuidv4() })
    id: string;

    @field({ decoder: WholesalerSettings })
    settings = WholesalerSettings.create({})

}


/**
 * Wholesaler data that is visible for the business
 */
export class WholesalerPrivate extends Wholesaler {
    @field({ decoder: WholesalerPrivateSettings })
    privateSettings = WholesalerPrivateSettings.create({})
}
