import { ArrayDecoder, AutoEncoder, DateDecoder, field, NumberDecoder } from "@simonbackx/simple-encoding";

import { CustomerPrivate } from "./Customer";
import { OrderPrivate } from "./Order";
import { WholesalerPrivate } from "./Wholesaler";
import { Promotion } from "./Promotion";

export class PromotionView extends AutoEncoder {
    @field({ decoder: CustomerPrivate })
    customer: CustomerPrivate

    @field({ decoder: DateDecoder })
    createdAt: Date
}

export class PromotionStats extends AutoEncoder {
    @field({ decoder: new ArrayDecoder(PromotionView) })
    views: PromotionView[] = []

    @field({ decoder: new ArrayDecoder(OrderPrivate) })
    orders: OrderPrivate[] = []

    @field({ decoder: new ArrayDecoder(WholesalerPrivate), version: 13 })
    wholesalers: WholesalerPrivate[] = []
}

export class PromotionStatsForExport extends AutoEncoder {
    @field({ decoder: Promotion, version: 25 })
    promotion: Promotion;

    @field({ decoder: PromotionView, version: 25 })
    view: PromotionView;

    @field({ decoder: new ArrayDecoder(OrderPrivate), version: 25 })
    orders: OrderPrivate[] = [];

    @field({ decoder: WholesalerPrivate, version: 25 })
    wholesaler: WholesalerPrivate;

    @field({ decoder: NumberDecoder, version: 25 })
    totalViewsCount: number;

    @field({ decoder: NumberDecoder, version: 25 })
    totalOrdersCount: number;
}