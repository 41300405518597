<template>
    <figure class="wholesaler-logo">
        <img v-if="logo && imageSrc" :src="imageSrc" draggable="false" :width="logo.source.width" :height="logo.source.height">
        <h1 v-else>
            {{ wholesaler.settings.name }}
        </h1>
        <h2 v-if="customer && (customerNumber && customerNumber > 1)">
            {{ customer.settings.businessName }}
        </h2>
    </figure>
</template>

<script lang="ts">
import { Customer, Wholesaler } from "@restofrit/structures";
import { Component, Prop,Vue } from "vue-property-decorator";

@Component
export default class WholesalerLogo extends Vue {
    @Prop({ required: true })
    wholesaler!: Wholesaler

    @Prop({ required: false })
    customer?: Customer

    @Prop({ required: false })
    customerNumber?: number

    get logo() {
        return this.wholesaler.settings.logo
    }

    get imageSrc() {
        return this.logo?.getPathForSize(500, undefined)
    }
}
</script>

<style lang="scss">
@use "~@restofrit/scss/base/variables.scss" as *;
@use "~@restofrit/scss/base/text-styles.scss" as *;

.wholesaler-logo {
    padding-bottom: 40px;

    img {
        margin: 0 auto;
        max-width: 200px;
        display: block;
    }

    h1 {
        text-align: center;
        @extend .style-h1;
    }

    h2 {
        text-align: center;
        margin-top: 10px;
    }
}
</style>