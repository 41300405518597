import { ArrayDecoder, AutoEncoder, BooleanDecoder, field, StringDecoder } from "@simonbackx/simple-encoding";
import { v4 as uuidv4 } from "uuid";
import { Language } from "./Language";
export class UserPermissions extends AutoEncoder {
    @field({ decoder: BooleanDecoder })
    isAdmin = false;

    @field({ decoder: BooleanDecoder, version: 22 })
    isTester = false;

    @field({ decoder: new ArrayDecoder(StringDecoder) })
    wholesalerIds: string[] = [];
}

export class User extends AutoEncoder {
    @field({ decoder: StringDecoder, defaultValue: () => uuidv4() })
    id: string;

    @field({ decoder: StringDecoder })
    email: string;

    @field({ decoder: UserPermissions, nullable: true })
    permissions: UserPermissions | null;

    @field({ decoder: StringDecoder, optional: true, nullable: true })
    defaultCustomerId: string | null = null;

    @field({ decoder: BooleanDecoder, optional: true })
    emailCanBeShared: boolean = false;

    @field({ decoder: StringDecoder, optional: true, version: 25 })
    language: string = Language.nl;
}

export class UserPrivate extends User {
    @field({ decoder: StringDecoder, optional: true })
    setPassword?: string
    
}