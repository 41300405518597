<template>
    <div class="rf-view background">
        <NavigationBar :title="title">
            <BackButton v-if="canPop" slot="left" @click="pop" />
        </NavigationBar>
        <main>
            <h1>{{ title }}</h1>
            
            <GeneralErrorsView :error-box="errorBox" />

            <SplitForm>
                <div slot="left" class="container">
                    <InputBox :title="$t('fields.businessName')" :error-box="errorBox" error-fields="businessName">
                        <input v-model="businessName" class="input" :placeholder="$t('fields.placeholders.businessName')" autocomplete="organization">
                    </InputBox>

                    <InputBox :title="$t('fields.internalClientName')">
                        <input v-model="internalClientName" class="input" :placeholder="$t('fields.placeholders.optional')">
                    </InputBox>

                    <InputBox :title="$t('fields.VATNumber')" :error-box="errorBox" error-fields="VATNumber">
                        <input v-model="VATNumber" class="input" :placeholder="$t('fields.placeholders.VATNumber')">
                    </InputBox>

                    <InputBox :title="$t('fields.customerNumber')" :error-box="errorBox" error-fields="number">
                        <input v-model="number" class="input" :placeholder="$t('fields.placeholders.optional')">
                    </InputBox>

                    <InputBox :title="$t('fields.language')" :error-box="errorBox" error-fields="language">
                        <select v-model="language" class="input">
                            <option v-for="lang in languages" :key="lang" :value="lang">
                                {{ getLanguageName(lang) }}
                            </option>
                        </select>
                    </InputBox>
                </div>
                <template slot="right">
                    <InputBox :title="$t('fields.email')" :error-box="errorBox" error-fields="email">
                        <input v-model="email" class="input" :placeholder="$t('fields.placeholders.emailAccount')" :disabled="patchedCustomer.user.permissions !== null">
                    </InputBox>
                    <p v-if="patchedCustomer.user.permissions !== null" class="style-description">
                        {{ $t('fields.descriptions.emailChangeBlocked') }}
                    </p>

                    <InputBox :title="$t('fields.contactName')" :error-box="errorBox" error-fields="contactName">
                        <input v-model="contactName" class="input" :placeholder="$t('fields.placeholders.contactName')">
                    </InputBox>

                    <InputBox :title="$t('fields.contactPhone')" :error-box="errorBox" error-fields="contactPhone">
                        <input v-model="contactPhone" class="input" :placeholder="$t('fields.placeholders.contactPhone')">
                    </InputBox>

                    <InputBox :title="$t('fields.city')" :error-box="errorBox" error-fields="city">
                        <input v-model="city" class="input" :placeholder="$t('fields.placeholders.optional')">
                    </InputBox>

                    <InputBox :title="$t('fields.postalCode')" :error-box="errorBox" error-fields="postalCode">
                        <input v-model="postalCode" class="input" :placeholder="$t('fields.placeholders.optional')">
                    </InputBox>

                    <InputBox :title="$t('fields.address')" :error-box="errorBox" error-fields="address">
                        <input v-model="address" class="input" :placeholder="$t('fields.placeholders.optional')">
                    </InputBox>
                </template>
            </SplitForm>

            <hr>
            <InputBox :title="$t('onboarding.categories.title')" :error-box="errorBox">
                <List>
                    <ListItem v-for="category in categories" :key="category" element-name="label" :selectable="true">
                        {{ getCategoryName(category) }}

                        <Checkbox slot="right" v-model="selectedCategories" :value="category" name="category" />
                    </ListItem>
                </List>
            </InputBox>

            <hr>

            <Checkbox v-model="isTester">
                {{ $t('customer.tester', { wholesaler: wholesaler.settings.name }) }}
            </Checkbox>

            <template v-if="isAdmin">
                <hr>
                <InputBox :title="$t('fields.wholesaler')" :error-box="errorBox">
                    <select v-model="wholesalerId" class="input">
                        <option v-for="wholesaler in wholesalers" :key="wholesaler.id" :value="wholesaler.id">
                            {{ wholesaler.settings.name }}
                        </option>
                    </select>
                </InputBox>
            </template>

            <hr>

            <button v-if="!isPending" class="secundary button" @click="moveToPending">
                {{ $t('editCustomer.buttons.moveToWaiting') }}
            </button>

            <button v-if="!isPending && !isBlocked" class="destructive button" @click="block">
                {{ $t('editCustomer.buttons.block') }}
            </button>
        </main>

        <Toolbar>
            <button v-if="isBlocked" class="secundary button" @click="accept">
                {{ $t('editCustomer.buttons.unblock') }}
            </button>
            <button v-if="isPending" class="destructive button" @click="decline">
                {{ $t('editCustomer.buttons.decline') }}
            </button>
            <button v-if="isPending" class="secundary button" @click="accept">
                {{ $t('editCustomer.buttons.accept') }}
            </button>

            <LoadingButton :loading="saving">
                <button class="primary button" @click="save">
                    {{ $t('buttons.save') }}
                </button>
            </LoadingButton>
        </Toolbar>
    </div>
</template>


<script lang="ts">
import { BackButton, Checkbox, ColorInput, InputBox, List, ListItem, LoadingButton, NavigationBar, SplitForm,Toolbar } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { CustomerCategory, CustomerCategoryHelper, CustomerPrivate, CustomerSettings, CustomerStatus, Language, LanguageHelper, User, Version, WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        ColorInput,
        Checkbox,
        SplitForm
    },
})
export default class CustomerEditView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    customer!: CustomerPrivate

    patch: AutoEncoderPatchType<CustomerPrivate> = CustomerPrivate.patch({})

    @Prop({ required: true })
    wholesaler!: WholesalerPrivate

    @Prop({ required: true })
    callback: (patch: AutoEncoderPatchType<CustomerPrivate>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    wholesalers: WholesalerPrivate[] = [this.wholesaler]
    loadingWholesalers = false

    get isAdmin() {
        return Session.shared.user?.permissions?.isAdmin ?? false
    }

    mounted() {
        if (this.isAdmin) {
            this.loadWholesalers().catch(console.error)
        }
    }

    async loadWholesalers() {
        this.loadingWholesalers = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingWholesalers = false
    }

    get title() {
        if (this.isNew) {
            return this.$t('editCustomer.createNewTitle').toString()
        }
        return this.patchedCustomer.settings.businessName
    }

    get isBlocked() {
        return this.patchedCustomer.settings.status === CustomerStatus.Blocked || this.patchedCustomer.settings.status === CustomerStatus.Declined
    }

    get isPending() {
        return this.patchedCustomer.settings.status === CustomerStatus.Pending
    }

    get isNew() {
        return this.customer.settings.businessName.length == 0
    }

    get patchedCustomer() {
        return this.customer.patch(this.patch)
    }

    addPatch(patch: AutoEncoderPatchType<CustomerPrivate>) {
        this.patch = this.patch.patch(patch)
    }

    patchSettings(patch: AutoEncoderPatchType<CustomerSettings>) {
        this.patch = this.patch.patch({
            settings: patch
        })
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.customer, { version: Version })
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }

    async block() {
        this.patchSettings(CustomerSettings.patch({ status: CustomerStatus.Blocked }))
        await this.save()
    }

    async decline() {
        this.patchSettings(CustomerSettings.patch({ status: CustomerStatus.Declined }))
        await this.save()
    }

    async accept() {
        this.patchSettings(CustomerSettings.patch({ status: CustomerStatus.Accepted }))
        await this.save()
    }

    async moveToPending() {
        this.patchSettings(CustomerSettings.patch({ status: CustomerStatus.Pending }))
        await this.save()
    }

    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            await this.callback(this.patch)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    get categories() {
        return Object.values(CustomerCategory)
    }

    getCategoryName(category: CustomerCategory) {
        return CustomerCategoryHelper.getName(category, this.$i18n as any)
    }

    get selectedCategories() {
        return this.patchedCustomer.settings.categories
    }

    set selectedCategories(categories: CustomerCategory[]) {
        const patch = CustomerSettings.patch({})
        for (const cat of this.categories) {
            patch.categories.addDelete(cat)
        }
        for (const cat of categories) {
            patch.categories.addPut(cat)
        }
        this.patchSettings(patch)
    }

     get languages() {
        return Object.values(Language)
    }

    // Patch methods
    get language() {
        return this.patchedCustomer.settings.language
    }

    set language(language: Language) {
        this.patchSettings(CustomerSettings.patch({
            language
        }))
    }

    getLanguageName(language: Language) {
        return LanguageHelper.getName(language)
    }

    get isTester() {
        return this.patchedCustomer.settings.isTester
    }

    set isTester(isTester: boolean) {
        this.patchSettings(CustomerSettings.patch({
            isTester
        }))
    }

    get city() {
        return this.patchedCustomer.settings.city
    }

    set city(city: string) {
        this.patchSettings(CustomerSettings.patch({
            city
        }))
    }

    get postalCode() {
        return this.patchedCustomer.settings.postalCode
    }

    set postalCode(postalCode: string) {
        this.patchSettings(CustomerSettings.patch({
            postalCode
        }))
    }

    get address() {
        return this.patchedCustomer.settings.address
    }

    set address(address: string) {
        this.patchSettings(CustomerSettings.patch({
            address
        }))
    }

    get wholesalerId() {
        return this.patchedCustomer.wholesalerId
    }

    set wholesalerId(wholesalerId: string) {
        this.addPatch(CustomerPrivate.patch({
            wholesalerId
        }))
    }

    get businessName() {
        return this.patchedCustomer.settings.businessName
    }

    set businessName(businessName: string) {
        this.patchSettings(CustomerSettings.patch({
            businessName
        }))
    }

    get VATNumber() {
        return this.patchedCustomer.settings.VATNumber
    }

    set VATNumber(VATNumber: string) {
        this.patchSettings(CustomerSettings.patch({
            VATNumber
        }))
    }

    get internalClientName() {
        return this.patchedCustomer.settings.internalClientName
    }

    set internalClientName(name: string) {
        this.patchSettings(CustomerSettings.patch({
            internalClientName: name
        }))
    }

    get number() {
        return this.patchedCustomer.settings.number
    }

    set number(number: string) {
        this.patchSettings(CustomerSettings.patch({
            number
        }))
    }

    get contactName() {
        return this.patchedCustomer.settings.contactName
    }

    set contactName(contactName: string) {
        this.patchSettings(CustomerSettings.patch({
            contactName
        }))
    }

    get contactPhone() {
        return this.patchedCustomer.settings.contactPhone
    }

    set contactPhone(contactPhone: string) {
        this.patchSettings(CustomerSettings.patch({
            contactPhone
        }))
    }

    get email() {
        return this.patchedCustomer.user.email
    }

    set email(email: string) {
        this.patch = this.patch.patch({
            user: User.patch({
                id: this.patchedCustomer.user.id,
                email
            })
        })
    }
}
</script>

<style scoped>
button {
    margin-bottom: 1px;
}
</style>