<template>
    <div>
        <label class="checkbox">
            <input v-model="inputChecked" type="checkbox" :disabled="disabled">
            <div>
                <div>
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.838867 4.49969L3.80061 8.18151L9.23047 0.817871" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div><slot /></div>
            </div>
        </label>
    </div>
</template>

<script lang="ts">
import { Component, Prop,Vue } from "vue-property-decorator";

@Component({
    "model": {
        "prop": "checked",
        "event": "change"
    },
})
export default class Checkbox extends Vue {
    @Prop({ default: false })
    checked!: boolean | any[];

    /**
     * If you use values, we'll transform the checkbox v-model into an array of values instead
     */
    @Prop({ default: null })
    value!: any;

    @Prop({ default: false })
    disabled!: boolean;

    get inputChecked() {
        if (this.value) {
            if (Array.isArray(this.checked)) {
                return this.checked.includes(this.value)
            }
            return false
        }

        if (Array.isArray(this.checked)) {
            // fallback for invalid behaviour
            console.warn("Expected a checkbox with a boolean v-model value")
            return false
        }

        return this.checked
    }

    set inputChecked(checked: boolean) {
        if (this.value) {
            if (Array.isArray(this.checked)) {
                const index = this.checked.indexOf(this.value)
                if (index !== -1) {
                    if (checked) {
                        // already included
                        return
                    }

                    // remove
                    const clone = this.checked.slice()
                    clone.splice(index, 1)
                    this.checkboxValue = clone
                    return
                }

                if (!checked) {
                    // already removed
                    return
                }

                const clone = this.checked.slice()
                clone.push(this.value)
                this.checkboxValue = clone
                return
            }
            if (checked) {
                this.checkboxValue = [this.value]
                return
            }
            this.checkboxValue = []
            return
        }

        // Normal boolean behaviour
        this.checkboxValue = checked
    }

    get checkboxValue() {
        return this.checked;
    }

    set checkboxValue(value) {
        this.$emit("change", value)

        this.$nextTick(() => {
            // Make sure we can handle uncaught v-models here by resetting the state
            if (this.checkboxValue != value) {
                if (this.$refs.checkbox) {
                    (this.$refs.checkbox as any).checked = this.inputChecked;
                }
            }
        })
    }
}
</script>
