<template>
    <InputBox :title="title" error-fields="time" :error-box="errorBox">
        <div class="time-input">
            <DateSelection v-if="withDate" v-model="dateValue" :disabled="disabled" />
            <input v-if="!hideTime" v-model="timeRaw" class="input" type="time" :class="{ error: !valid }" :placeholder="placeholder" :autocomplete="autocomplete" :disabled="disabled" required @change="validate">
        </div>
    </InputBox>
</template>

<script lang="ts">
import { Formatter } from '@restofrit/utilities';
import { ErrorBox, Validator } from "@simonbackx/simple-error-forms";
import { SimpleError } from '@simonbackx/simple-errors';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import DateSelection from "./DateSelection.vue"
import InputBox from "./InputBox.vue"

@Component({
    components: {
        InputBox,
        DateSelection
    }
})
export default class TimeInput extends Vue {
    @Prop({ default: "" }) 
    title: string;

    @Prop({ default: null }) 
    validator: Validator | null

    timeRaw = "";
    valid = true;

    @Prop({ default: false })
    withDate: boolean

    @Prop({ required: true })
    value!: Date

    @Prop({ default: false })
    disabled!: boolean

    @Prop({ default: "" })
    placeholder!: string

    @Prop({ default: "" })
    autocomplete!: string

    errorBox: ErrorBox | null = null

    @Prop({ default: false })
    hideTime: boolean

    @Watch('value')
    onValueChanged(val: Date) {
        if (val === null) {
            return
        }
        this.timeRaw = Formatter.timeIso(this.value)
    }

    mounted() {
        if (this.validator) {
            this.validator.addValidation(this, () => {
                return this.validate()
            })
        }
        this.timeRaw = Formatter.timeIso(this.value)

    }

    destroyed() {
        if (this.validator) {
            this.validator.removeValidation(this)
        }
    }

    get dateValue() {
        return this.value
    }

    set dateValue(d: Date) {
        this.$emit("input", d)
    }

    async validate() {
        this.timeRaw = this.timeRaw.trim().toLowerCase()

        const regex = /^([0-9]{1,2}:)?[0-9]{1,2}$/;
        
        if (!regex.test(this.timeRaw)) {
            this.errorBox = new ErrorBox(new SimpleError({
                "code": "invalid_field",
                "message": "Tijdstip ongeldig. Verwachte vorm: 14:30",
                "field": "time"
            }))
            return false

        } else {
            const split = this.timeRaw.split(":")
            let hours = parseInt(split[0])
            let minutes = parseInt(split[1] ?? "0")

            if (isNaN(hours)) {
                hours = 0;
            }

            if (isNaN(minutes)) {
                minutes = 0;
            }

            if (hours > 24 || minutes > 60) {
                this.errorBox = new ErrorBox(new SimpleError({
                    "code": "invalid_field",
                    "message": "Tijdstip ongeldig. Verwachte vorm: 14:30",
                    "field": "time"
                }))
                return false
            }
 
            const d = new Date(this.value.getTime())
            d.setHours(hours, minutes, 0, 0)
            this.$emit("input", d)

            this.errorBox = null
            return true
        }
    }
}
</script>

<style lang="scss">
@use "~@restofrit/scss/base/variables.scss" as *;

.time-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    > *:first-child {
        margin-right: 5px;
    }

    > *:last-child {
        max-width: none;
        padding-right: 0;
        padding-left: 5px;
        text-align: center;
    }

    // Hide clear button
    input::-webkit-clear-button {
        display: none;
    }

    input::-ms-clear {
        display: none;
    }

    /* Hide the cancel button */
    ::-webkit-search-cancel-button { 
        -webkit-appearance: none; 
    }

    /* Hide the magnifying glass */
    ::-webkit-search-results-button {
        -webkit-appearance: none; 
    }
}
</style>