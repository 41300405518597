<template>
    <InputBox :title="title" error-fields="color" :error-box="errorBox">
        <div class="color-box">
            <div class="color" :style="{ backgroundColor: colorRawBuffer }">
                <input v-model="colorRawBuffer" type="color" :disabled="disabled" @change="validate(true)">
            </div>
            <input ref="input" v-model="colorRaw" class="input" :class="{ error: !valid }" :placeholder="placeholder" :autocomplete="autocomplete" :disabled="disabled" @change="validate(true)">
        </div>
    </InputBox>
</template>

<script lang="ts">
import { SimpleError } from '@simonbackx/simple-errors';
import InputBox from "./InputBox.vue";
import { Component, Prop,Vue, Watch } from "vue-property-decorator";
import { ErrorBox, Validator } from '@simonbackx/simple-error-forms';

@Component({
    components: {
        InputBox
    }
})
export default class ColorInput extends Vue {
    @Prop({ default: "" }) 
    title: string;

    @Prop({ default: null }) 
    validator: Validator | null;

    colorRaw = "";
    valid = true;

    @Prop({ default: null })
    value!: string | null

    @Prop({ default: true })
    required!: boolean

    @Prop({ default: false })
    disabled!: boolean

    @Prop({ default: "Kleur in HEX formaat" })
    placeholder!: string

    @Prop({ default: "email" })
    autocomplete!: string

    errorBox: ErrorBox | null = null

    @Watch('value')
    onValueChanged(val: string | null) {
        if (val === null) {
            return
        }
        this.colorRaw = val
    }

    @Watch('colorRaw')
    onRawChanged(val: string | null) {
        this.validate(false)
    }

    /**
     * Prevent passing invalid colors
     */
    get colorRawBuffer() {
        return this.value
    }

    set colorRawBuffer(val: string | null) {
        this.colorRaw = val ?? ""
    }

    mounted() {
        if (this.validator) {
            this.validator.addValidation(this, () => {
                return this.validate()
            })
        }

        this.colorRaw = this.value ?? ""
    }

    destroyed() {
        if (this.validator) {
            this.validator.removeValidation(this)
        }
    }

    validate(update = true) {
        this.colorRaw = this.colorRaw.trim().toUpperCase()

        if (!this.required && this.colorRaw.length == 0) {
            if (update) {
                this.errorBox = null
            }
            this.valid = true

            if (update && this.value !== null) {
                this.$emit("input", null)
            }
            return true
        }

        if (this.colorRaw.length == 6 && !this.colorRaw.startsWith("#")) {
            this.colorRaw = "#"+this.colorRaw;
        }
        
        const regex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/;
        
        if (!regex.test(this.colorRaw)) {
            if (update) {
                this.errorBox = new ErrorBox(new SimpleError({
                    "code": "invalid_field",
                    "message": "Ongeldig kleur",
                    "field": "color"
                }))
                if (this.value !== null && !this.required) {
                    this.$emit("input", null)
                }
            }
            console.warn("Invalid color", this.colorRaw)
            this.valid = false
            
            return false

        } else {
            console.warn("Valid color", this.colorRaw)
            this.valid = true
            if (update && this.colorRaw !== this.value) {
                this.$emit("input", this.colorRaw)
            }
            this.errorBox = null
            return true
        }
    }

    focus() {
        (this.$refs.input as any)?.focus()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@use "~@restofrit/scss/base/variables.scss" as *;

.color-box {
    display: flex;
    flex-direction: row;

    > .color {
        width: 50px;
        height: 50px;
        border-radius: $border-radius;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        margin-right: 10px;
        flex-shrink: 0;

        > input {
            opacity: 0;
            width: 50px;
            height: 50px;
        }
    }
}
</style>
