<template>
    <component :is="elementName" class="rf-list-item" :class="{ selectable }" @click="$emit('click', $event)">
        <div class="left">
            <slot name="left" />
        </div>
        <div class="main">
            <div>
                <div class="middle">
                    <slot />
                </div>
                <div class="right">
                    <slot name="right" />
                </div>
            </div>
            <hr>
        </div>
    </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ListItem extends Vue {
    @Prop({ default: 'article', type: String })
    elementName!: string;

    @Prop({ default: false, type: Boolean })
    selectable!: boolean;
}
</script>

<style lang="scss">
@use '~@restofrit/scss/base/variables' as *;
@use '~@restofrit/scss/base/text-styles' as *;
@use 'sass:math';

.rf-list-item {
    padding-left: var(--rf-horizontal-padding, 15px);
    padding-right: 0;
    margin: 0;
    display: flex;
    flex-direction: row;

    >.left {
        flex-shrink: 0;
        padding-top: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        min-width: 0; // flexbox disable becoming bigger than parent

        &:empty {
            display: none;
        }
    }

    &.left-center {
        >.left {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &.right-description {
        >.main>div>.right {
            @extend .style-description;
            text-align: right;
            flex-shrink: 10;
            padding-left: 15px;
        }
    }

    &.right-stack {
        .right {
            display: flex;
            flex-direction: row;
            align-items: center;

            >* {
                margin: 0 5px;

                &:last-child {
                    margin-right: 0;
                }
            }

            >.button {
                margin: -5px 5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &.no-margin {
        .right {
            >* {
                margin: 0;
            }

            >.button {
                margin: -5px 0;
            }
        }
    }

    >.main {
        flex-grow: 1;

        // Make sure the hr drops to the bottom if the left is longer
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0; // flexbox disable becoming bigger than parent

        >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;

            >.middle {
                padding-top: 20px;
                padding-right: 15px;
                padding-bottom: 20px;
                flex-grow: 1;
                min-width: 0; // flexbox disable becoming bigger than parent
            }

            >.right {
                margin-left: auto;
                padding-right: var(--rf-horizontal-padding, 15px);
                min-width: 0; // flexbox disable becoming bigger than parent
                flex-shrink: 0;
                padding-top: 10px;
                padding-bottom: 10px;

                &:empty {
                    display: none;
                }
            }
        }

        >hr {
            border: 0;
            outline: 0;
            height: $border-width;
            width: 100%;
            background: var(--color-current-border, #{$color-border});
            border-radius: math.div($border-width, 2); // Updated line
            margin: 0;
        }
    }

    // Wrap on smartphones (because content is too long)
    &.smartphone-wrap {
        @media (max-width: 450px) {
            >.main>div {
                display: block;

                >.middle {
                    padding-right: var(--rf-horizontal-padding, 15px);
                    padding-bottom: 0px;
                }

                >.right {
                    padding-top: 5px;
                    padding-bottom: 15px;
                }
            }
        }
    }

    &:last-child,
    &.no-border {
        >.main>hr {
            display: none;
        }
    }

    &.selectable {
        touch-action: manipulation;
        user-select: none;
        transition: background-color 0.2s 0.1s;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        cursor: pointer;

        >.main {
            >hr {
                transition: opacity 0.2s 0.1s;
            }
        }

        &:active {
            transition: none;
            background: $color-background-shade;
            background: var(--color-current-background-shade, $color-background-shade);

            >.main {
                >hr {
                    transition: none;
                    opacity: 0;
                }
            }
        }
    }
}
</style>