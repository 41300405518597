<template>
    <component :is="elementName" class="promotion-box" :class="{selectable}" @click="$emit('click', $event)">
        <div :class="{ noHeader: !header }">
            <div class="absolute">
                <header v-if="header">
                    <h1 v-text="settings.title.get(language || $i18n)" />
                    <h2 v-if="settings.subtitle.get(language || $i18n)" v-text="settings.subtitle.get(language || $i18n)" />
                </header>
                <figure>
                    <img v-if="imageSrc" :src="imageSrc" :class="{ contain }" draggable="false">
                </figure>
                <footer v-if="settings.promotionText.get(language || $i18n) || settings.promotionSubtext.get(language || $i18n)">
                    <h1 v-text="settings.promotionText.get(language || $i18n)" />
                    <h2 v-if="settings.promotionSubtext.get(language || $i18n)" :class="{ strike: settings.promotionSubtextStrikethrough }" v-text="settings.promotionSubtext.get(language || $i18n)" />
                </footer>
            </div>
        </div>
    </component>
</template>

<script lang="ts">
import { Promotion } from "@restofrit/structures";
import { Component, Prop,Vue } from "vue-property-decorator";

@Component
export default class PromotionBox extends Vue {
    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: null })
    wholesalerId!: string | null

    @Prop({  default: null })
    language!: string | null

    @Prop({ default: 'article', type: String })
    elementName!: string;

    @Prop({ default: false, type: Boolean })
    selectable!: boolean;

    @Prop({ default: true, type: Boolean })
    header!: boolean;

    get imageSrc() {
        const image = this.settings.getImageForLanguage(this.language || this.$i18n.locale);
        return image?.getPathForSize(500, undefined);
    }

    get contain() {
        return this.settings.containImage
    }

    get settings() {
        return this.promotion.getSettingsFor(this.wholesalerId)
    }
}
</script>

<style lang="scss">
@use "~@restofrit/scss/base/variables.scss" as *;
@use "~@restofrit/scss/base/text-styles.scss" as *;

.promotion-box {
    max-width: 400px;
    margin: 0 auto;
    
    > div {
        padding-bottom: 100%;
        position: relative;
        background: $color-background;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        overflow: hidden;

        &.noHeader {
            padding-bottom: 80%;
        }
    }

    &.selectable {
        cursor: pointer;
        touch-action: manipulation;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout:none;

        > div {
            transition: transform 0.2s 0.15s;
        }

        &:active > div {
            transform: scale(0.9, 0.9);
            transition: transform 0.1s;
        }
    }

    .absolute {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        z-index: 0;

        > header {
            flex-shrink: 0;
            text-align: center;
            padding: 20px 15px 12px 15px;

            > h1 {
                @extend .style-h3;
                white-space: pre-wrap;

                &:last-child {
                    padding: 7px 0;
                }
            }

            > h2 {
                padding-top: 4px;
                white-space: pre-wrap;
                @extend .style-subtitle;
            }
        }

        > figure {
            background: $color-background;
            flex-grow: 1;
            z-index: -2;
            position: relative;

            > img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.contain {
                    object-fit: contain;
                }
            }
        }

        > footer {
            flex-shrink: 0;
            background: $color-primary;
            color: $color-background;
            position: relative;
            text-align: center;
            padding: 10px 15px 18px 15px;

            > h1 {
                @extend .style-h2;
                white-space: pre-wrap;
            }

            > h2 {
                padding-top: 3px;
                @extend .style-h5;
                white-space: pre-wrap;

                &.strike {
                    text-decoration: line-through;
                }
            }

            &:before {
                position: absolute;
                content: "";
                background: $color-primary;
                transform: skew(0, -2deg);
                transform-origin: 0 0;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: -1;
            }
        }

    }
}
</style>