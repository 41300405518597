<template>
    <div class="input date-selection" :disabled="disabled" @click="openContextMenu">
        <span>{{ dateText }}</span>
        <span v-if="!disabled" class="icon arrow-down-small gray" />
    </div>
</template>

<script lang="ts">
import { Formatter } from "@restofrit/utilities"
import { ComponentWithProperties, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { Component, Mixins,Prop } from "vue-property-decorator";

import DateSelectionView from './DateSelectionView.vue';

@Component
export default class DateSelection extends Mixins(NavigationMixin) {
    @Prop({ default: false })
    disabled!: boolean

    @Prop({ default: () => {
        const d = new Date()
        d.setHours(0, 0, 0, 0)
        return d
    } })
    value: Date

    get dateText() {
        return Formatter.date(this.value)
    }

    openContextMenu(event) {
        if (this.disabled) {
            return
        }
        const el = this.$el as HTMLElement;
        const displayedComponent = new ComponentWithProperties(DateSelectionView, {
            x: el.getBoundingClientRect().left + el.offsetWidth - 300,
            y: el.getBoundingClientRect().top + el.offsetHeight,
            selectedDay: this.value,
            setDate: (value: Date) => {
                const d = new Date(value.getTime())
                d.setHours(this.value.getHours(), this.value.getMinutes(), 0, 0)
                this.$emit("input", value)
            }
        });
        this.present(displayedComponent.setDisplayStyle("overlay"));
    }
}
</script>

<style lang="scss">
@use "~@restofrit/scss/base/variables.scss" as *;

.input.date-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:active, &:hover {
        background: $color-background-primary;
    }

    > span {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.icon {
            flex-shrink: 0;
        }
    }
}
</style>