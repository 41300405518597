<template>
    <div class="rf-view background promotions-edit-view">
        <NavigationBar :title="title">
            <BackButton v-if="canPop" slot="left" @click="pop" />

            <EditLanguageSelection slot="right" :isPromotionLanguage="true"/>
        </NavigationBar>
        <main>
            <h1>{{ title }}</h1>

            <p v-if="!canEdit" class="info-box">
                {{ $t('editPromotion.managedByDescription') }}
            </p>
            
            <GeneralErrorsView :error-box="errorBox" />

            <SplitForm>
                <div slot="left" class="container">
                    <InputBox :title="$t('fields.status')" :error-box="errorBox" error-fields="language">
                        <select v-model="status" class="input" :disabled="!canEdit">
                            <option v-for="s in promotionStatusList" :key="s" :value="s">
                                {{ getStatusName(s) }}
                            </option>
                        </select>
                    </InputBox>

                    <InputBox v-if="overrideWholesalerId || wholesaler" :title="$t('fields.promotionNumber')" :error-box="errorBox" error-fields="subtitle">
                        <input v-model="overridePromotionNumber" class="input" :placeholder="$t('fields.placeholders.promotionNumber').toString()">
                    </InputBox>
                        
                    <InputBox :title="$t('fields.promotionTitle')" :error-box="errorBox" error-fields="title">
                        <textarea v-model="promotionTitle" class="input" :placeholder="settings.title.get(language) || $t('fields.placeholders.promotionTitle').toString()" :rows="promotionTitleLines" />
                    </InputBox>
                    <p v-if="originalPromotionTitle" class="style-description">
                        Resto-Frit: {{ originalPromotionTitle }}
                    </p>

                    <InputBox :title="$t('fields.promotionSubtitle')" :error-box="errorBox" error-fields="subtitle">
                        <input v-model="subtitle" class="input" :placeholder="settings.subtitle.get(language) || $t('fields.placeholders.promotionSubtitle').toString()">
                    </InputBox>

                    <p v-if="originalSubtitle" class="style-description">
                        Resto-Frit: {{ originalSubtitle }}
                    </p>

                    <InputBox :title="$t('fields.promotionText')" :error-box="errorBox" error-fields="promotionText">
                        <textarea v-model="promotionText" class="input" :placeholder="settings.promotionText.get(language) || $t('fields.placeholders.promotionText').toString()" :rows="promotionTextLines" />
                    </InputBox>

                    <p v-if="originalPromotionText" class="style-description">
                        Resto-Frit: {{ originalPromotionText }}
                    </p>

                    <InputBox :title="$t('fields.promotionSubtext')" :error-box="errorBox" error-fields="promotionSubtext">
                        <input v-model="promotionSubtext" class="input" :placeholder="settings.promotionSubtext.get(language) || $t('fields.placeholders.optional').toString()">
                    </InputBox>

                    <p v-if="originalPromotionSubtext" class="style-description">
                        Resto-Frit: {{ originalPromotionSubtext }}
                    </p>

                    <Checkbox v-if="settings.promotionSubtext.get(language).length > 0" v-model="promotionSubtextStrikethrough">
                        {{ $t('fields.promotionSubtextStrikethrough') }}
                    </Checkbox>

                    <p v-if="settings.promotionSubtext.get(language).length > 0 && promotionSubtextStrikethrough != originalPromotionSubtextStrikethrough" class="style-description">
                        Resto-Frit: {{ originalPromotionSubtextStrikethrough ? $t('fields.promotionSubtextStrikethroughOn') : $t('fields.promotionSubtextStrikethroughOff') }}
                    </p>

                    <InputBox :title="$t('fields.promotionDescription')" :error-box="errorBox" error-fields="description">
                        <textarea v-model="description" class="input" :placeholder="settings.description.get(language) || $t('fields.placeholders.promotionDescription').toString()" />
                    </InputBox>

                    <InputBox :title="$t('fields.promotionInternalDescription')" :error-box="errorBox" error-fields="internalDescription">
                        <textarea v-model="internalDescription" class="input" :placeholder="$t('fields.placeholders.optional').toString()" />
                    </InputBox>

                    <hr v-if="!canEdit">

                    <h2 v-if="!canEdit">
                        {{ $t('editPromotion.notEditableDescription') }}
                    </h2>

                    <hr>

                    <Checkbox v-model="requireDeliveryAddress" :disabled="!canEdit">
                        {{ $t('fields.deliveryAddress') }}
                    </Checkbox>

                    <Checkbox v-model="canBeCombined" :disabled="!canEdit">
                        {{ $t('fields.promotionCanBeCombined') }}
                    </Checkbox>

                    <hr v-if="!canEdit">

                    <TimeInput v-model="startAt" :title="$t('fields.promotionStartAt')" :with-date="true" :disabled="!canEdit" />
                    <TimeInput v-model="endAt" :title="$t('fields.promotionEndAt')" :with-date="true" :disabled="!canEdit" />

                    <Checkbox v-model="enableOrders" :disabled="!canEdit">
                        {{ $t('fields.promotionEnableOrders') }}
                    </Checkbox>

                    <hr>

                    <Checkbox v-if="canEdit" v-model="enableMin">
                        {{ $t('fields.enableMinimum') }}
                    </Checkbox>

                    <Checkbox v-if="canEdit" v-model="enableMax">
                        {{ $t('fields.enableMaximum') }}
                    </Checkbox>

                    <Checkbox v-if="canEdit" v-model="enableTotal">
                        {{ $t('fields.enableTotal') }}
                    </Checkbox>

                    <InputBox v-if="enableMin" :title="$t('fields.minimumAmount')" :error-box="errorBox" error-fields="minimumAmount">
                        <NumberInput v-model="minimumAmount" suffix-singular="stuk" suffix="stuks" :min="1" :stepper="canEdit" :disabled="!canEdit" />
                    </InputBox>

                    <InputBox v-if="enableMax" :title="$t('fields.maximumAmount')" :error-box="errorBox" error-fields="maximumAmount">
                        <NumberInput v-model="maximumAmount" suffix-singular="stuk" suffix="stuks" :min="minimumAmount || 1" :stepper="canEdit" :disabled="!canEdit" />
                    </InputBox>

                    <InputBox v-if="enableTotal" :title="$t('fields.totalAmount')" :error-box="errorBox" error-fields="totalAmount">
                        <NumberInput v-model="totalAmount" suffix-singular="stuk" suffix="stuks" :min="1 || 1" :stepper="canEdit" :disabled="!canEdit" />
                    </InputBox>

                    <template v-if="canEdit">
                        <hr>
                        <h2>{{ $t('editPromotion.notificationsTitle') }}</h2>

                        <p v-if="patchedPromotion.settings.notifications.length" class="style-tag duplicate" @click="duplicateNotification(patchedPromotion.settings.notifications[0])">{{ $t('editPromotion.copyTag') }}</p>

                        <List>
                            <ListItem v-for="notification of patchedPromotion.settings.notifications" :key="notification.id" :selectable="true" class="right-stack" @click="editNotification(notification)">
                                <h3>
                                    {{ notification.usedNotificationTitle(patchedPromotion.settings).get(language) }}
                                </h3>
                                <p class="style-description">
                                    {{ formatDateTime(notification.date) }}
                                </p>
                                <span v-if="notification.didSend" slot="right" class="style-tag">{{ $t('editPromotion.notificationSendTag') }}</span>
                                <span slot="right" class="icon arrow-right gray" />
                            </ListItem>
                        </List>

                        <button v-if="canEdit" type="button" class="button secundary" @click="newNotification">
                            {{ $t('editPromotion.newNotificationButton') }}
                        </button>
                    </template>

                    <hr>

                    <Checkbox v-if="canEdit" v-model="enableButton">
                        {{ $t('fields.enableButton') }}
                    </Checkbox>

                    <template v-if="enableButton">
                        <InputBox :title="$t('fields.buttonTitle')" :error-box="errorBox" error-fields="button.title">
                            <input v-model="buttonTitle" :disabled="!canEdit" class="input" :placeholder="usedButtonTitle || $t('fields.placeholders.buttonTitle').toString()">
                        </InputBox>

                        <InputBox :title="$t('fields.buttonUrl')" :error-box="errorBox" error-fields="button.url">
                            <input v-model="buttonUrl" :disabled="!canEdit" class="input" :placeholder="usedButtonUrl || $t('fields.placeholders.buttonUrl').toString()">
                        </InputBox>
                    </template>
                </div>
                <template slot="right">
                    <InputBox :title="$t('fields.example')" :error-box="errorBox" error-fields="example">
                        <div ref="example" class="example">
                            <PromotionBox :promotion="patchedPromotion" :wholesaler-id="overrideWholesalerId" :language="language" />
                        </div>
                    </InputBox>
                    <p v-if="canEdit" class="style-description image-text">
                        {{ $t('editPromotion.photoDescription') }}
                    </p>

                    <UploadImageButton 
                        v-if="canEdit" 
                        v-model="imageForCurrentLanguage" 
                        :resolutions="resolutions"
                    >
                        <div type="button" class="button secundary">
                            {{ $t('editPromotion.uploadPhotoButton') }}
                        </div>
                    </UploadImageButton>

                    <hr v-if="canEdit">

                    <Checkbox v-if="canEdit" v-model="containPicture">
                        {{ $t('fields.containPicture') }}
                        <p class="style-description">
                            {{ $t('fields.descriptions.containPicture') }}
                        </p>
                    </Checkbox>

                    <hr>
                    <InputBox :title="$t('fields.category')" :error-box="errorBox" error-fields="emptyCategories">
                        <List>
                            <ListItem v-for="category in categories" :key="category" element-name="label" :selectable="canEdit">
                                {{ getCategoryName(category) }}

                                <Checkbox slot="right" v-model="selectedCategories" :value="category" name="category" :disabled="!canEdit" />
                            </ListItem>
                        </List>
                    </InputBox>
                </template>
            </SplitForm>


            <div v-if="wholesaler === null" class="container">
                <hr>
                <h2>Artikelnummers</h2>

                <Spinner v-if="loadingWholesalers" />

                <InputBox v-for="wholesaler of wholesalers" :key="wholesaler.id" :title="wholesaler.settings.name" :error-box="errorBox">
                    <Checkbox slot="right" :checked="!isExcluded(wholesaler.id)" @change="toggleExclusion(wholesaler.id)" name="excludeWholesaler" :disabled="!canEdit" />
                    <input :value="getPromotionNumberFor(wholesaler.id)" class="input" :placeholder="$t('fields.placeholders.promotionNumber').toString()" @input="setPromotionNumberFor(wholesaler.id, $event.target.value)">
                </InputBox>
            </div>

            <template v-if="!isNew && canEdit">
                <hr>
                <button type="button" class="button destructive" @click="deleteMe">
                    {{ $t('buttons.delete') }}
                </button>
            </template>
        </main>

        <Toolbar>
            <button v-if="!isNew" type="button" class="button secundary" @click="openStats">
                {{ $t('editPromotion.viewStats') }}
            </button>
            <LoadingButton :loading="saving">
                <button class="primary button" @click="save">
                    {{ $t('buttons.save') }}
                </button>
            </LoadingButton>
        </Toolbar>
    </div>
</template>


<script lang="ts">
import { BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput, PromotionBox,Spinner,SplitForm, TimeInput, Toolbar, UploadImageButton, WholesalerTheme } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { Notification, PromotionStatusHelper } from "@restofrit/structures";
import { CustomerCategory, CustomerCategoryHelper, Image, Promotion, PromotionButton, PromotionSettings, PromotionStatus, ResolutionFit, ResolutionRequest, TranslatedString, Version, WholesalerPrivate } from "@restofrit/structures";
import { Language } from "@restofrit/structures/esm/dist";
import { Formatter } from "@restofrit/utilities";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { SimpleError, SimpleErrors } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { EditPromotionLanguageHelper } from "../../classes/EditLanguageHelper";
import EditLanguageSelection from "../components/EditLanguageSelection.vue";
import NotificationEditView from "./NotificationEditView.vue";
import PromotionStatsView from "./PromotionStatsView.vue";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        Checkbox,
        TimeInput,
        NumberInput,
        SplitForm,
        PromotionBox,
        UploadImageButton,
        EditLanguageSelection,
        Spinner
    },
})
export default class PromotionEditView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: null })
    wholesaler!: WholesalerPrivate | null

    @Prop({ default: false })
    canEdit!: boolean

    @Prop({ default: false })
    isNew!: boolean

    copyTagAdded = false;

    patch: AutoEncoderPatchType<Promotion> = Promotion.patch({})

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<Promotion>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    overrideWholesalerId: string | null = this.wholesaler && this.promotion.wholesalerId === null ? (this.wholesaler?.id ?? null) : null

    wholesalers: WholesalerPrivate[] = []
    loadingWholesalers = false

    EditPromotionLanguageHelper = EditPromotionLanguageHelper

    get language() {
        return EditPromotionLanguageHelper.language
    }

    mounted() {
        // Reset the editing language every time we edit a promotion, to the language of the user
        const lang = this.wholesaler?.settings.language ?? this.$i18n.locale.slice(0, 2)
        if (Object.values(Language).includes(lang as Language)) {
            EditPromotionLanguageHelper.language = lang as Language
        }


        this.updateTheme()

        if (this.wholesaler === null) {
            this.loadWholesalers().catch(console.error)
        }
    }

    formatDateTime(date: Date) {
        return Formatter.dateTime(date)
    }

    duplicateNotification(notification: Notification) {        
        const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
        const newNotification = Notification.create({
            title: notification.title,
            description: notification.description,
            date: notification.date
        })
        arr.addPut(newNotification)

        this.present({
            components: [
                new ComponentWithProperties(NotificationEditView, {
                    notification: newNotification,
                    isNew: true,
                    promotion: this.patchedPromotion,
                    callback: (notifications: PatchableArrayAutoEncoder<Notification>) => {
                        arr.merge(notifications)
                        this.patchSettings(PromotionSettings.patch({
                            notifications: arr
                        }))
                    }
                })
            ],
            modalDisplayStyle: "side-view"
        })
    }

    editNotification(notification: Notification) {
        this.present({
            components: [
                new ComponentWithProperties(NotificationEditView, {
                    notification,
                    promotion: this.patchedPromotion,
                    callback: (notifications: PatchableArrayAutoEncoder<Notification>) => {
                        this.patchSettings(PromotionSettings.patch({
                            notifications
                        }))
                    }
                })
            ],
            modalDisplayStyle: "side-view"
        })
    }

    newNotification() {
        const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
        const notification = Notification.create({})
        arr.addPut(notification)

        this.present({
            components: [
                new ComponentWithProperties(NotificationEditView, {
                    notification,
                    isNew: true,
                    promotion: this.patchedPromotion,
                    callback: (notifications: PatchableArrayAutoEncoder<Notification>) => {
                        arr.merge(notifications)
                        this.patchSettings(PromotionSettings.patch({
                            notifications: arr
                        }))
                    }
                })
            ],
            modalDisplayStyle: "side-view"
        })
    }

    async loadWholesalers() {
        this.loadingWholesalers = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingWholesalers = false
    }

    openStats() {
        this.present(new ComponentWithProperties(PromotionStatsView, {
            promotion: this.promotion,
            wholesaler: this.wholesaler
        }).setDisplayStyle("side-view"))
    }

    updateTheme() {
        if (!this.wholesaler) {
            return
        }
        WholesalerTheme.loadColors(this.wholesaler, this.$refs.example as HTMLElement)
    }

    get title() {
        if (Object.values(this.promotion.settings.title.translations).length == 0) {
            return this.$t('editPromotion.newPromotionTitle');
        } else if(this.isNew && !this.copyTagAdded) {
            var copyTitle = "";
            switch (EditPromotionLanguageHelper.language) {
            case 'en':
                copyTitle = this.patchedPromotion.settings.title.translations.en + this.$t('editPromotion.copyTag').toString();
                break;
            case 'fr':
                copyTitle = this.patchedPromotion.settings.title.translations.fr + this.$t('editPromotion.copyTag').toString();
                break;
            default:
                copyTitle = this.patchedPromotion.settings.title.translations.nl + this.$t('editPromotion.copyTag').toString();
                break;
            }

            this.patchOverrideSettings(PromotionSettings.patch({
                title: TranslatedString.createPatch(copyTitle, EditPromotionLanguageHelper.language)
            }))

            this.copyTagAdded = true;
            return copyTitle;
        }
        return this.patchedPromotion.settings.title.get(EditPromotionLanguageHelper.language)
    }

    get promotionStatusList(){
        return Object.values(PromotionStatus)
    }

    getStatusName(status: PromotionStatus){
        return PromotionStatusHelper.getName(status, this.$i18n as any)
    }

    get categories() {
        return Object.values(CustomerCategory)
    }

    getCategoryName(category: CustomerCategory) {
        return CustomerCategoryHelper.getName(category, this.$i18n as any)
    }

    get patchedPromotion() {
        return this.promotion.patch(this.patch)
    }

    applyPatch(patch: AutoEncoderPatchType<Promotion>) {
        this.patch = this.patch.patch(patch)
    }

    patchSettings(patch: AutoEncoderPatchType<PromotionSettings>) {
        this.patch = this.patch.patch({
            settings: patch
        })
    }

    patchOverrideSettings(patch: AutoEncoderPatchType<PromotionSettings>, wholesalerId: string | null = null) {
        const id = (wholesalerId ?? this.overrideWholesalerId)
        if (id === null) {
            this.patchSettings(patch)
            return
        }
        
        const wholesalerOverrides: any = new Map(this.patchedPromotion.wholesalerOverrides)
        
        wholesalerOverrides.set(id, wholesalerOverrides.get(id)?.patch(patch) ?? patch)
        this.patch = this.patch.patch({
            wholesalerOverrides
        })
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.promotion, { version: Version })
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }


    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const errors = new SimpleErrors();

            if (this.patchedPromotion.startAt.getTime() > this.patchedPromotion.endAt.getTime()) {
                throw new Error(this.$t('errors.invalidDates').toString());
            }

            if (this.patchedPromotion.settings.categories.length === 0) {
                errors.addError(new SimpleError({
                    code: "emptyCategory",
                    message: "Choose at least one category",
                    human: this.$t('errors.emptyCategories').toString(),
                    field: "emptyCategories"
                }))
                this.saving = false;
            }

            errors.throwIfNotEmpty();

            const arr: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
            this.patch.id = this.promotion.id
            arr.addPatch(this.patch)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    async deleteMe() {
        if (this.saving) {
            return
        }
        if (!confirm(this.$t('confirmation.deletePromotion').toString())) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
            arr.addDelete(this.promotion.id)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    // Patch methods
    get promotionTitle() {
        return this.settings.title.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set promotionTitle(title: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            title: TranslatedString.createPatch(title ? title : null, EditPromotionLanguageHelper.language)
        }))
    }

    get originalPromotionTitle() {
        const original = this.rfsettings.title.getIfExists(EditPromotionLanguageHelper.language) ?? ""
        if (original === this.promotionTitle) {
            return ""
        }
        return original
    }

    get promotionTitleLines() {
        return Math.min(Math.max(1, this.promotionTitle.split("\n").length), 2)
    }

    get subtitle() {
        return this.settings.subtitle.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set subtitle(subtitle: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            subtitle: TranslatedString.createPatch(subtitle ? subtitle : null, EditPromotionLanguageHelper.language)
        }))
    }

    get originalSubtitle() {
        const original = this.rfsettings.subtitle.getIfExists(EditPromotionLanguageHelper.language) ?? ""
        if (original === this.subtitle) {
            return ""
        }
        return original
    }

    get promotionText() {
        return this.settings.promotionText.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set promotionText(promotionText: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            promotionText: TranslatedString.createPatch(promotionText ? promotionText : null, EditPromotionLanguageHelper.language)
        }))
    }

    get originalPromotionText() {
        const original = this.rfsettings.promotionText.getIfExists(EditPromotionLanguageHelper.language) ?? ""
        if (original === this.promotionText) {
            return ""
        }
        return original
    }

    get promotionTextLines() {
        return Math.min(Math.max(1, this.promotionText.split("\n").length), 2)
    }

    get promotionSubtext() {
        return this.settings.promotionSubtext.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set promotionSubtext(promotionSubtext: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            promotionSubtext: TranslatedString.createPatch(promotionSubtext ? promotionSubtext : null, EditPromotionLanguageHelper.language)
        }))
    }

    get originalPromotionSubtext() {
        const original = this.rfsettings.promotionSubtext.getIfExists(EditPromotionLanguageHelper.language) ?? ""
        if (original === this.promotionSubtext) {
            return ""
        }
        return original
    }

    get promotionSubtextStrikethrough() {
        return this.settings.promotionSubtextStrikethrough
    }

    set promotionSubtextStrikethrough(promotionSubtextStrikethrough: boolean) {
        this.patchOverrideSettings(PromotionSettings.patch({
            promotionSubtextStrikethrough
        }))
    }

    get originalPromotionSubtextStrikethrough() {
        return this.rfsettings.promotionSubtextStrikethrough
    }

    get description() {
        return this.settings.description.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set description(description: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            description: TranslatedString.createPatch(description ? description : null, EditPromotionLanguageHelper.language)
        }))
    }

    get internalDescription() {
        return this.settings.internalDescription
    }

    set internalDescription(internalDescription: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            internalDescription
        }))
    }

    get notificationTitle() {
        return this.patchedPromotion.settings.notificationTitle.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set notificationTitle(notificationTitle: string) {
        this.patchSettings(PromotionSettings.patch({
            notificationTitle: TranslatedString.createPatch(notificationTitle ? notificationTitle : null, EditPromotionLanguageHelper.language)
        }))
    }

    get notificationDescription() {
        return this.patchedPromotion.settings.notificationDescription.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set notificationDescription(notificationDescription: string) {
        this.patchSettings(PromotionSettings.patch({
            notificationDescription: TranslatedString.createPatch(notificationDescription ? notificationDescription : null, EditPromotionLanguageHelper.language)
        }))
    }

    get status() {
        return this.patchedPromotion.status
    }

    set status(status: PromotionStatus) {
        this.applyPatch(Promotion.patch({
            status
        }))
    }

    get startAt() {
        return this.patchedPromotion.startAt
    }

    set startAt(startAt: Date) {
        this.applyPatch(Promotion.patch({
            startAt
        }))
    }

    get endAt() {
        return this.patchedPromotion.endAt
    }

    set endAt(endAt: Date) {
        this.applyPatch(Promotion.patch({
            endAt
        }))
    }

    get sendNotification() {
        return this.patchedPromotion.notificationAt !== null
    }

    set sendNotification(enable: boolean) {
        if (enable === this.sendNotification) {
            return
        }
        if (enable) {
            this.notificationAt = this.startAt
        } else {
            this.notificationAt = null
        }
    }

    resendNotification() {
        if (this.patchedPromotion.didSendNotification === false) {
            return
        }

        this.applyPatch(Promotion.patch({
            didSendNotification: false
        }))
    }

    get notificationAt() {
        return this.patchedPromotion.notificationAt
    }

    set notificationAt(notificationAt: Date | null) {
        this.applyPatch(Promotion.patch({
            notificationAt
        }))
    }

    get requireDeliveryAddress() {
        return this.patchedPromotion.settings.requireDeliveryAddress
    }

    set requireDeliveryAddress(requireDeliveryAddress: boolean) {
        this.patchSettings(PromotionSettings.patch({
            requireDeliveryAddress
        }))
    }

    get canBeCombined() {
        return this.patchedPromotion.settings.canBeCombined
    }

    set canBeCombined(canBeCombined: boolean) {
        this.patchSettings(PromotionSettings.patch({
            canBeCombined
        }))
    }

    get enableOrders() {
        return this.patchedPromotion.settings.enableOrders
    }

    set enableOrders(enableOrders: boolean) {
        this.patchSettings(PromotionSettings.patch({
            enableOrders
        }))
    }

    get enableMin() {
        return this.patchedPromotion.settings.minimumAmount !== null
    }

    set enableMin(enableMin: boolean) {
        if (enableMin === this.enableMin) {
            return
        }
        if (enableMin) {
            this.minimumAmount = 1
        } else {
            this.minimumAmount = null
        }
    }

    get minimumAmount() {
        return this.patchedPromotion.settings.minimumAmount
    }

    set minimumAmount(minimumAmount: number | null) {
        this.patchSettings(PromotionSettings.patch({
            minimumAmount
        }))
    }

    get enableMax() {
        return this.patchedPromotion.settings.maximumAmount !== null
    }

    set enableMax(enableMax: boolean) {
        if (enableMax === this.enableMax) {
            return
        }
        if (enableMax) {
            this.maximumAmount = 1
        } else {
            this.maximumAmount = null
        }
    }

    get maximumAmount() {
        return this.patchedPromotion.settings.maximumAmount
    }

    set maximumAmount(maximumAmount: number | null) {
        this.patchSettings(PromotionSettings.patch({
            maximumAmount
        }))
    }

    get enableTotal() {
        return this.patchedPromotion.settings.totalAmount !== null
    }

    set enableTotal(enableTotal: boolean) {
        if (enableTotal === this.enableTotal) {
            return
        }
        if (enableTotal) {
            this.totalAmount = 1
        } else {
            this.totalAmount = null
        }
    }

    get totalAmount() {
        return this.patchedPromotion.settings.totalAmount
    }

    set totalAmount(totalAmount: number | null) {
        this.patchSettings(PromotionSettings.patch({
            totalAmount
        }))
    }

    get containPicture() {
        return this.patchedPromotion.settings.containImage
    }

    set containPicture(containImage: boolean) {
        this.patchSettings(PromotionSettings.patch({
            containImage
        }))
    }

    get image() {
        return this.patchedPromotion.settings.image
    }
 
    set image(image: Image | null) {
        this.patchSettings(PromotionSettings.patch({
            image
        }))
    }

    get resolutions(): ResolutionRequest[] {
        return [
            ResolutionRequest.create({
                width: 500,
                fit: ResolutionFit.Cover
            }),
            ResolutionRequest.create({
                width: 1500,
                fit: ResolutionFit.Cover
            })
        ]
    }

    // Buttons
    patchButtons(patch: AutoEncoderPatchType<PromotionButton>) {
        this.patchSettings(PromotionSettings.patch({
            button: patch
        }))
    }

    get enableButton() {
        return this.patchedPromotion.settings.button !== null
    }

    set enableButton(enableButton: boolean) {
        if (enableButton === this.enableButton) {
            return
        }
        if (enableButton) {
            this.patchSettings(PromotionSettings.patch({
                button: PromotionButton.create({})
            }))
        } else {
            this.patchSettings(PromotionSettings.patch({
                button: null
            }))
        }
    }

    get usedButtonTitle() {
        return this.patchedPromotion.settings.button?.text.get(EditPromotionLanguageHelper.language) ?? ""
    }

    get buttonTitle() {
        return this.patchedPromotion.settings.button?.text.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set buttonTitle(text: string) {
        this.patchButtons(PromotionButton.patch({
            text: TranslatedString.createPatch(text ? text : null, EditPromotionLanguageHelper.language)
        }))
    }

    get usedButtonUrl() {
        return this.patchedPromotion.settings.button?.url.get(EditPromotionLanguageHelper.language) ?? ""
    }

    get buttonUrl() {
        return this.patchedPromotion.settings.button?.url.getIfExists(EditPromotionLanguageHelper.language) ?? ""
    }

    set buttonUrl(url: string) {
        this.patchButtons(PromotionButton.patch({
            url: TranslatedString.createPatch(url ? url : null, EditPromotionLanguageHelper.language)
        }))
    }

    get selectedCategories() {
        return this.patchedPromotion.settings.categories
    }

    set selectedCategories(categories: CustomerCategory[]) {
        const patch = PromotionSettings.patch({})
        for (const cat of this.categories) {
            patch.categories.addDelete(cat)
        }
        for (const cat of categories) {
            patch.categories.addPut(cat)
        }
        this.patchSettings(patch)
    }


    
    isExcluded(wholesalerId: string) {
        return this.patchedPromotion.excludeWholesalers.includes(wholesalerId);
    }

    toggleExclusion(wholesalerId: string) {
        const index = this.patchedPromotion.excludeWholesalers.indexOf(wholesalerId);
        if (index > -1) {
            // if it was excluded, remove it from the array
            this.patchedPromotion.excludeWholesalers.splice(index, 1);
        } else {
            // if it was not excluded, add it to the array
            this.patchedPromotion.excludeWholesalers.push(wholesalerId);
        }
        const excludeWholesalers: any = this.patchedPromotion.excludeWholesalers;
        this.applyPatch(Promotion.patch({ excludeWholesalers }))
    }

    get overridePromotionNumber() {
        return this.settings.number
    }

    set overridePromotionNumber(number: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            number
        }))
    }

    getPromotionNumberFor(wholesalerId: string) {
        return this.patchedPromotion.getSettingsFor(wholesalerId).number
    }

    setPromotionNumberFor(wholesalerId: string, number: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            number
        }), wholesalerId)
    }

    get settings() {
        return this.patchedPromotion.getSettingsFor(this.overrideWholesalerId)
    }

    get rfsettings() {
        return this.patchedPromotion.settings
    }

    get imageForCurrentLanguage() {
        return this.patchedPromotion.settings.getImageForLanguage(this.language);
    }

    set imageForCurrentLanguage(image: Image | null) {
        const newMap = new Map(this.patchedPromotion.settings.image);
        if (image) {
            newMap.set(this.language, image);
        } else {
            newMap.delete(this.language);
        }

        this.patchSettings(PromotionSettings.patch({
            image: newMap
        }));
    }
}
</script>

<style lang="scss">
@use '~@restofrit/scss/base/variables.scss' as *;

.promotions-edit-view {
    .example {
        background: $color-background-shade;
        border-radius: $border-radius;
        padding: 30px 20px;
    }

    .promotion-box {
        // Width for iPhone 8
        max-width: 345px;
    }

    .image-text {
        padding-bottom: 15px;
    }

    .duplicate {
        cursor: pointer;
        background-color: rgb(137, 212, 255);
        margin-top: 15px;
    }
    .duplicate:hover {
        background-color: rgba(137, 212, 255, 0.413);
    }
}

</style>