<template>
    <FieldErrorsView :error-fields="errorFields" :error-box="errorBox" class="input-box">
        <h4 v-if="title">
            <label>{{ title }}</label>
            <div class="right">
                <slot name="right" />
            </div>
        </h4>
        <slot />
    </FieldErrorsView>
</template>


<script lang="ts">
import { ErrorBox, FieldErrorsView} from '@simonbackx/simple-error-forms';
import { Component, Prop,Vue } from "vue-property-decorator";

@Component({
    components: {
        FieldErrorsView
    }
})
export default class InputBox extends Vue {
    @Prop({ default: ""}) errorFields: string;
    @Prop({ default: null }) errorBox: ErrorBox | null;

    @Prop({ default: ""})
    title!: string
}
</script>


<style lang="scss">
@use '~@restofrit/scss/base/text-styles.scss';
@use '~@restofrit/scss/base/variables.scss' as *;

.input-box {
    padding: 8px 0;
    display: block;
    max-width: 600px;

    > h4 {
        margin: 0;
        @extend .style-h4;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 34px;
        transition: color 0.2s;

        > label {
            flex-grow: 1; // fix safari newline glitch
            flex-shrink: 2;
        }

        > .right {
            margin-left: auto;
            flex-shrink: 1;
        }

        ~ * {
            margin-top: 5px;

            &:last-child {
                margin-top: 0; // error box
            }
        }

        + * {
            margin-top: 0;
        }
    }

    &:focus-within {
        > h4 {
            color: $color-primary;
        }
    }

    &.input-errors {
        > h4 {
            //color: $color-error;
        }
    }

    &.max {
        max-width: none;
    }
}
</style>