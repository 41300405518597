<template>
    <button class="button text" @click="openDropdown">
        <span>{{ language }}</span>
        <span class="icon arrow-down-small" />
    </button>
</template>

<script lang="ts">
import { Language, LanguageHelper } from '@restofrit/structures';
import { ComponentWithProperties, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { EditLanguageHelper, EditPromotionLanguageHelper } from '../../classes/EditLanguageHelper';
import LanguageSelectionContextMenu from './LanguageSelectionContextMenu.vue';
import { switchLanguage } from '@restofrit/i18n';

@Component
export default class EditLanguageSelection extends Mixins(NavigationMixin) {
    @Prop({ default: false, type: Boolean })
    isPromotionLanguage!: boolean;
    
    // Make reactive
    EditLanguageHelper = EditLanguageHelper;
    EditPromotionLanguageHelper = EditPromotionLanguageHelper;

    get language() {
        if(this.isPromotionLanguage) {
            return LanguageHelper.getName(EditPromotionLanguageHelper.language)
        }

        return LanguageHelper.getName(EditLanguageHelper.language)
    }

    openDropdown(event: any) {
        const displayedComponent = new ComponentWithProperties(LanguageSelectionContextMenu, {
            isPromotionLanguage: this.isPromotionLanguage,
            x: event.clientX,
            y: event.clientY + 10,
            setLanguage: async (lang: Language) => {
                console.log("set language ", lang)
                if(this.isPromotionLanguage) {
                    EditPromotionLanguageHelper.language = lang
                } else {
                    EditLanguageHelper.language = lang
                    try {
                        await switchLanguage(lang);
                        window.location.reload();
                    } catch (error) {
                        console.log(error);
                    }
                }
                
            }
        });
        this.present(displayedComponent.setDisplayStyle("overlay"));
    }
}
</script>