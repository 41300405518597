<template>
    <label class="upload-image-button">
        <LoadingButton :loading="uploading">
            <slot />
        </LoadingButton>
        <input type="file" accept="image/png, image/jpeg, image/svg+xml" @change="changedFile">
    </label>
</template>

<script lang="ts">
import { Image, ResolutionRequest, Version } from "@restofrit/structures";
import { Component, Prop, Vue } from "vue-property-decorator";

import LoadingButton from "./LoadingButton.vue";
import { Session } from '@restofrit/networking';

@Component({
    components: {
        LoadingButton
    }
})
export default class UploadImageButton extends Vue {
    /**
     * All the resolutions you want the image to resize to
     */
    @Prop({ default: null })
    resolutions: ResolutionRequest[] | null
    
    @Prop({ default: null })
    value: Image | null;

    uploading = false

    changedFile(event) {
        if (!event.target.files || event.target.files.length != 1) {
            return;
        }
        if (this.uploading) {
            return;
        }

        const file = event.target.files[0];
        const resolutions = this.resolutions ?? [ResolutionRequest.create({ height: 720 })]

        const formData = new FormData();
        formData.append("file", file);
        formData.append("resolutions", JSON.stringify(resolutions.map(r => r.encode({ version: Version }))))

        this.uploading = true;

        Session.shared.authenticatedServer
            .request({
                method: "POST",
                path: "/upload/image",
                body: formData,
                decoder: Image,
                timeout: 2*60*1000
            })
            .then(response => {
                this.$emit("input", response.data)
            })
            .catch(e => {
                console.error(e);
                alert(e);
            })
            .finally(() => {
                this.uploading = false;

                 // Clear input
                event.target.value = null;
            });
    }
}
</script>

<style lang="scss">
.upload-image-button {
    > input {
        display: none;
    }
}
</style>
