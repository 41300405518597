import { CustomerPrivate, CustomerStatus, Promotion, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities"
import VueI18n from "vue-i18n";
import * as XLSX from "xlsx";
import { EditLanguageHelper } from "../../classes/EditLanguageHelper";
import { PromotionStatsForExport } from "@restofrit/structures/src/PromotionStats";

function createFilteredPromotionSheet(promotionStatsToExport: PromotionStatsForExport[], i18n: VueI18n) {
    /* make worksheet */
    const data: any[][] = [
        [
            i18n.t("promotion.statistics.columns.promotionTitle"),
            i18n.t("promotion.statistics.columns.promotionStartAt"),
            i18n.t("promotion.statistics.columns.promotionEndAt"),
            i18n.t("promotion.statistics.columns.clientName"),
            i18n.t("promotion.statistics.columns.wholesaler"),
            i18n.t("promotion.statistics.columns.viewDate"),
            i18n.t("promotion.statistics.columns.orderDate"),
            i18n.t("promotion.statistics.columns.orderAmount"),
            i18n.t("fields.city"),
            i18n.t("fields.postalCode"),
            i18n.t("fields.address"),
            i18n.t("promotion.statistics.viewsSheetName"),
            i18n.t("promotion.statistics.orderSheetName"),
        ],
    ];

    for (const p of promotionStatsToExport) {
        const orderedDate = p.orders[0] ? p.orders[0].createdAt : "";
        const orderedAmount = p.orders.reduce((sum, order) => sum + order.settings.amount, 0);

        data.push([
            p.promotion.settings.title.get(EditLanguageHelper.language),
            p.promotion.startAt,
            p.promotion.endAt,
            p.view.customer.settings.businessName,
            p.wholesaler.settings.name,
            p.view.createdAt,
            orderedDate,
            orderedAmount,
            p.view.customer.settings.city,
            p.view.customer.settings.postalCode,
            p.view.customer.settings.address,
            p.totalViewsCount,
            p.totalOrdersCount,
        ]);
    }

    console.log({data});

    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column width
    ws["!cols"] = [];
    for (const column of data[0]) {
        if (column.toLowerCase().includes("naam")) {
            ws["!cols"].push({ width: 25 });
        } else if (column.toLowerCase().includes("mail")) {
            ws["!cols"].push({ width: 30 });
        } else {
            ws["!cols"].push({ width: 20 });
        }
    }

    return ws;
}

export function exportFilteredPromotionStats(promotionStatsToExport: PromotionStatsForExport[], i18n: VueI18n) {
    const wb = XLSX.utils.book_new();

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(
        wb,
        createFilteredPromotionSheet(promotionStatsToExport, i18n),
        i18n.t("promotion.statistics.promotionSheetName").toString()
    );
    XLSX.writeFile(
        wb,
        Formatter.slug(
            i18n.t("promotion.statistics.excelFileName").toString()
        ) + ".xlsx"
    );
}