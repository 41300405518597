<template>
    <div class="rf-view root-menu-view shade">
        <img class="logo" src="@restofrit/assets/images/logo/resto-frit.png">

        <EditLanguageSelection slot="left" />

        <main>            
            <List>
                <ListItem :selectable="true" @click="openWholesalers">
                    {{ $t('wholesalers.title') }}
                </ListItem>
                <ListItem :selectable="true" @click="openAdmins">
                    {{ $t('admins.title') }}
                </ListItem>
                <ListItem :selectable="true" @click="openPromotions">
                    {{ $t('promotions.title') }}
                </ListItem>
                <ListItem :selectable="true" @click="logout">
                    {{ $t('buttons.logout') }}
                </ListItem>
            </List>

            <hr>

            <h3>{{ $t('root.manageWholeslaers') }}</h3>

            <Spinner v-if="loading" />
            
            <List v-else>
                <ListItem v-for="wholesaler in wholesalers" :key="wholesaler.id" :selectable="true" @click="openWholesaler(wholesaler)">
                    {{ wholesaler.settings.name }}
                </ListItem>
            </List>
        </main>
    </div>
</template>


<script lang="ts">
import { InputBox, List, ListItem, Spinner } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin, SplitViewController } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import AdminsView from "../admins/AdminsView.vue";
import PromotionsView from "../promotions/PromotionsView.vue";
import WholesalersView from "../wholesalers/WholesalersView.vue";
import WholesalerMenuView from "./WholesalerMenuView.vue";
import EditLanguageSelection from "../components/EditLanguageSelection.vue";

@Component({
    components: {
        EditLanguageSelection,
        InputBox,
        List,
        ListItem,
        Spinner
    },
})
export default class RootMenuView extends Mixins(NavigationMixin) {
    wholesalers: WholesalerPrivate[] = []
    loading = false

    mounted() {
        HistoryManager.setUrl("/")
        if (this.splitViewController && !this.splitViewController.shouldCollapse()) {
            this.openWholesalers()
        }
        this.load().catch(e => {
            console.error(e)
        })
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = response.data
        } catch (e) {
            console.error(e)
        }
        this.loading = false
    }

    openWholesalers() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(WholesalersView, {})
        }))
        
    }

    openAdmins() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(AdminsView, {})
        }))
    }

    openPromotions() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(PromotionsView, {
                wholesaler: null
            })
        }))
    }

    openWholesaler(wholesaler: WholesalerPrivate) {
        this.present(new ComponentWithProperties(SplitViewController, {
            root:  new ComponentWithProperties(NavigationController, { 
                root: new ComponentWithProperties(WholesalerMenuView, {
                    wholesaler
                })
            })
        }))
    }

    logout() {
        Session.shared.logout()
    }
    
}
</script>

<style lang="scss">
@use '~@restofrit/scss/base/text-styles.scss' as *;

.root-menu-view {
    --rf-horizontal-padding: 20px;

    .logo {
        height: 100px;
        width: auto;
        display: block;
        align-self: center;
        justify-self: center;
        margin-bottom: 20px;;
    }

    > main {
        > h3 {
            @extend .style-h4;
        }
    }
}

</style>