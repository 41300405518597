<template>
    <div class="rf-view background">
        <NavigationBar :title="$t('admins.title')">
            <BackButton v-if="canPop" slot="left" @click="pop" />
            <button slot="right" class="button text" @click="createNew">
                {{ $t('buttons.add') }}
            </button>
        </NavigationBar>
        <main>
            <h1>{{ $t('admins.title') }}</h1>

            <GeneralErrorsView :error-box="errorBox" />

            <Spinner v-if="loading" />
            
            <List v-else>
                <ListItem v-for="admin in admins" :key="admin.id" :selectable="true" @click="editAdmin(admin)">
                    <h3>
                        {{ admin.email }}
                    </h3>
                    <p class="style-description">
                        {{ adminDescription(admin) }}
                    </p>

                    <span slot="right" class="icon arrow-right gray" />
                </ListItem>
            </List>
        </main>
    </div>
</template>


<script lang="ts">
import { BackButton, InputBox, List, ListItem, NavigationBar, Spinner } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { UserPermissions, UserPrivate, WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, Decoder, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import AdminEditView from "./AdminEditView.vue";

@Component({
    components: {
        NavigationBar,
        InputBox,
        List,
        ListItem,
        BackButton,
        GeneralErrorsView,
        Spinner
    },
})
export default class AdminsView extends Mixins(NavigationMixin) {
    admins: UserPrivate[] = []
    wholesalers: WholesalerPrivate[] = []
    errorBox: ErrorBox | null = null
    loading = false

    mounted() {
        this.load().catch(e => {
            console.error(e)
        })
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/admins",
                decoder: new ArrayDecoder(UserPrivate as Decoder<UserPrivate>)
            })
            this.admins = response.data

            const r = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = r.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async patchAdmins(patch: PatchableArrayAutoEncoder<UserPrivate>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/admins",
            body: patch,
            decoder: new ArrayDecoder(UserPrivate as Decoder<UserPrivate>)
        })
        this.admins = response.data
    }

    adminDescription(admin: UserPrivate) {
        if (admin.permissions?.isAdmin) {
            return "Hoofdbeheerder"
        }
        return (admin.permissions?.wholesalerIds ?? []).map(id => this.wholesalers.find(w => w.id === id)?.settings.name ?? "?").join(", ")
    }

    generatePassword(length) {
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    }
    
    createNew() {
        const admin = UserPrivate.create({
            email: "",
            setPassword: this.generatePassword(15),
            permissions: UserPermissions.create({}),
            emailCanBeShared: false,
            defaultCustomerId: null
        })
        this.show(new ComponentWithProperties(AdminEditView, {
            admin,
            wholesalers: this.wholesalers,
            isNew: true,
            callback: async (patch: PatchableArrayAutoEncoder<UserPrivate>) => {
                await this.patchAdmins(patch),
                this.load().catch(e => {
                    console.error(e)
                })
            }
        }))
    }

    editAdmin(admin: UserPrivate) {
        this.show(new ComponentWithProperties(AdminEditView, {
            admin,
            wholesalers: this.wholesalers,
            isNew: false,
            callback: async (patch: PatchableArrayAutoEncoder<UserPrivate>) => {
                await this.patchAdmins(patch)
            }
        }))
    }
}
</script>