<template>
    <div class="rf-view background promotions-view">
        <NavigationBar :title="$t('promotions.title')">
            <BackButton v-if="canPop" slot="left" @click="pop" />
            <button slot="right" class="button text" @click="createNew">
                {{ $t('buttons.add') }}
            </button>
        </NavigationBar>
        <main>
            <h1>{{ $t('promotions.title') }}</h1>
            <SegmentedControl v-model="selectedTab" :items="tabs" :labels="tabLabels" />
            
            <GeneralErrorsView :error-box="errorBox" />

            <label class="input-with-icon">
                <span class="icon search" />
                <input class="input" id="search" name="search" :placeholder="$t('promotions.filters.search').toString()" type="search" 
                    inputmode="search" enterkeyhint="search" autocorrect="off" autocomplete="off" spellcheck="false" autocapitalize="off" 
                    @input="searchQuery = $event.target.value">
            </label>

            <div class="time-filter-container">
                <div class="time-input-wrapper">
                    <TimeInput
                        v-model="startAt"
                        :title="$t('promotions.filters.periodStart')"
                        :error-box="errorBox"
                        error-fields="startAt"
                        :with-date="true"
                        :hide-time="true"
                    />
                </div> 
                <div class="time-input-wrapper">
                    <TimeInput
                        v-model="endAt"
                        :title="$t('promotions.filters.periodEnd')"
                        :error-box="errorBox"
                        error-fields="endAt"
                        :with-date="true"
                        :hide-time="true"
                    />
                </div>
            </div>

            <InputBox :title="$t('promotions.filters.category')" :error-box="errorBox" error-fields="example">
                <List>
                    <ListItem v-for="category in categories" :key="category" element-name="label">
                        {{ getCategoryName(category) }}

                        <Checkbox slot="right" v-model="selectedCategories" :value="category" name="category"/>
                    </ListItem>
                </List>
            </InputBox>

            <div class="row">
                <button type="button" class="primary button" @click="resetFilters()">
                    {{ $t('promotions.filters.reset') }}
                </button>

                <button type="button" class="primary button" @click="changeSortDateDirection()">
                    {{ $t('promotions.filters.sortByDate') }} {{ sortByDateDirection }}
                </button>
            </div>

            <button type="button" class="primary button" @click="downloadExcel()">
                {{ $t('promotions.filters.promotionExport') }}
            </button>

            <hr>

            <Spinner v-if="loading" />
            <List v-else>
                <ListItem v-for="promotion in filteredPromotions" :key="promotion.id" :selectable="true" @click="editPromotion(promotion)" class="right-stack">
                    <p class="style-tag duplicate" @click="duplicatePromotion(promotion)">{{ $t('editPromotion.copyTag') }}</p>
                    <h3>{{ promotion.getSettingsFor(wholesalerId).title.get($i18n) }} {{ promotion.getSettingsFor(wholesalerId).subtitle.get($i18n) }}</h3>
                    <p class="style-description">
                        {{ promotionDescription(promotion) }}
                    </p>

                    <p v-if="promotion.status != 'Active'" class="style-tag">
                        {{ getStatusName(promotion.status) }}
                    </p>
                    
                    <template slot="right">
                        <span v-if="promotion.wholesalerId === null" class="style-tag">Resto-Frit</span>
                        <span class="icon arrow-right gray" />
                    </template>
                </ListItem>
            </List>
            <p v-if="!loading && filteredPromotions.length == 0" class="error-box">
                {{ $t('promotions.emptyDescription') }}
            </p>
        </main>
    </div>
</template>


<script lang="ts">
import { BackButton, Checkbox, InputBox, List, ListItem, NavigationBar, SegmentedControl,Spinner, TimeInput } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { CustomerCategory, CustomerCategoryHelper, PromotionSettings, PromotionStats, PromotionStatusHelper, TranslatedString } from "@restofrit/structures";
import { Promotion, PromotionStatus, WholesalerPrivate } from "@restofrit/structures";
import { PromotionStatsForExport } from "@restofrit/structures/src/PromotionStats";
import { Formatter } from "@restofrit/utilities";
import { ArrayDecoder, Decoder, PatchableArray, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { EditLanguageHelper } from "../../classes/EditLanguageHelper";

import PromotionEditView from "./PromotionEditView.vue";
import { exportFilteredPromotionStats } from "./PromotionsFilteredExcelExporter";

@Component({
    components: {
        Checkbox,
        NavigationBar,
        InputBox,
        List,
        ListItem,
        BackButton,
        GeneralErrorsView,
        Spinner,
        SegmentedControl,
        TimeInput
    },
})
export default class PromotionsView extends Mixins(NavigationMixin) {
    promotions: Promotion[] = []
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ default: null })
    wholesaler!: WholesalerPrivate | null

    tabs = ["active", "past"]
    tabLabels = [this.$t("promotions.tabs.active"),this.$t("promotions.tabs.archived")]

    selectedTab = this.tabs[0]

    searchQuery = ""

    startAtFilter: Date = new Date(new Date().setMonth(new Date().getMonth() - 2));
    endAtFilter: Date = new Date(new Date().setMonth(new Date().getMonth() + 2));

    categoryFilter: CustomerCategory[] = this.categories;
    
    sortByDateDirection = "DESC";

    mounted() {
        this.load().catch(e => {
            console.error(e)
        })
    }

    get wholesalerId() {
        return this.wholesaler?.id ?? null
    }

    get filteredPromotions() {
        const now = new Date()
        let promotionsToReturn = this.promotions;
        if (this.selectedTab === "active") {
            promotionsToReturn = promotionsToReturn.filter(p => p.endAt > now)
        } else {
            promotionsToReturn = promotionsToReturn.filter(p => p.endAt <= now)
        }

        if(this.searchQuery.length > 0) {
            promotionsToReturn = promotionsToReturn.filter(p => {
                return p.settings.title.get(EditLanguageHelper.language).toLocaleLowerCase().includes(this.searchQuery.toLocaleLowerCase())
            })
        }
        
        if(this.startAtFilter.getTime() != this.endAtFilter.getTime()) {
            promotionsToReturn = promotionsToReturn.filter(p => {
                // date filter interval includes p.startAt                
                return p.startAt >= this.startAtFilter && p.startAt <= this.endAtFilter
            })
        }

        if(this.categories.length != this.categoryFilter.length) {
            promotionsToReturn = promotionsToReturn.filter(p => {
                return p.settings.categories.some(c => this.categoryFilter.includes(c));
            })
        }

        if(this.sortByDateDirection === "ASC") {
            promotionsToReturn = promotionsToReturn.sort((a, b) => a.startAt.getTime() - b.startAt.getTime());
        } else {
            promotionsToReturn = promotionsToReturn.sort((a, b) => b.startAt.getTime() - a.startAt.getTime());
        }

        return promotionsToReturn;
    }

    get startAt() {
        return this.startAtFilter;
    }

    set startAt(startAt: Date) {
        this.startAtFilter = startAt;
    }

    get endAt() {
        return this.endAtFilter;
    }

    set endAt(endAt: Date) {
        this.endAtFilter = endAt;
    }

    get categories() {
        return Object.values(CustomerCategory)
    }

    getCategoryName(category: CustomerCategory) {
        return CustomerCategoryHelper.getName(category, this.$i18n as any)
    }

    get selectedCategories() {
        return this.categoryFilter
    }

    set selectedCategories(categories: CustomerCategory[]) {
        this.categoryFilter = [];
        for (const cat of categories) {
            this.categoryFilter.push(cat);
        }
    }

    get isAdmin() {
        return Session.shared.user?.permissions?.isAdmin ?? false
    }

    getStatusName(status: PromotionStatus) {
        return PromotionStatusHelper.getName(status, this.$i18n as any)
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: this.wholesaler ? "/dashboard/wholesalers/"+this.wholesaler.id+"/promotions" : "/dashboard/promotions",
                decoder: new ArrayDecoder(Promotion as Decoder<Promotion>)
            })
            this.promotions = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async patchPromotions(patch: PatchableArrayAutoEncoder<Promotion>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: this.wholesaler ? "/dashboard/wholesalers/"+this.wholesaler.id+"/promotions" : "/dashboard/promotions",
            body: patch,
            decoder: new ArrayDecoder(Promotion as Decoder<Promotion>)
        })
        this.promotions = response.data
    }
    
    createNew() {
        const promotion = Promotion.create({
            wholesalerId: this.wholesaler?.id ?? null
        })
        this.show(new ComponentWithProperties(PromotionEditView, {
            promotion,
            wholesaler: this.wholesaler,
            canEdit: true,
            isNew: true,
            callback: async (arr: PatchableArrayAutoEncoder<Promotion>) => {
                const all: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
                all.addPut(promotion)
                all.merge(arr)
                await this.patchPromotions(all)
            }
        }))
    }

    duplicatePromotion(originalPromotion: Promotion) {
        const newPromotion = Promotion.create({
            settings: PromotionSettings.create(originalPromotion.settings),
            wholesalerOverrides: originalPromotion.wholesalerOverrides,
            excludeWholesalers: originalPromotion.excludeWholesalers,
            wholesalerId: originalPromotion.wholesalerId,
            startAt: originalPromotion.startAt,
            endAt: originalPromotion.endAt,
            notificationAt: originalPromotion.notificationAt,
            status: originalPromotion.status
        });
        newPromotion.settings.notifications = [];
                
        this.show(new ComponentWithProperties(PromotionEditView, {
            promotion: newPromotion,
            wholesaler: this.wholesaler,
            canEdit: true,
            isNew: true,
            callback: async (arr: PatchableArrayAutoEncoder<Promotion>) => {
                const all: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
                all.addPut(newPromotion)
                all.merge(arr)
                await this.patchPromotions(all)
            }
        }))
    }

    editPromotion(promotion: Promotion) {
        this.show(new ComponentWithProperties(PromotionEditView, {
            promotion,
            canEdit: promotion.wholesalerId !== null || this.isAdmin,
            wholesaler: this.wholesaler,
            isNew: false,
            callback: async (arr: PatchableArrayAutoEncoder<Promotion>) => {
                await this.patchPromotions(arr)
            }
        }))
    }

    promotionDescription(promotion: Promotion) {
        return Formatter.dateTime(promotion.startAt) + " - "+Formatter.dateTime(promotion.endAt)
    }

    async downloadExcel() {
        var promotionsToExport: PromotionStatsForExport[] = [];
        for (const p of this.filteredPromotions) {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/promotions/" + p.id + "/stats",
                decoder: PromotionStats as Decoder<PromotionStats>
            })
            console.log({promoStats: response.data});
            

            if(response.data.views) {
                for (const view of response.data.views) {
                    const pStatForExport: PromotionStatsForExport = PromotionStatsForExport.create({
                        promotion: p,
                        view: view,
                        orders: response.data.orders.filter(o => o.customer.id === view.customer.id),
                        wholesaler: response.data.wholesalers.find(w => w.id === view.customer.wholesalerId),
                        totalViewsCount: response.data.views.length ?? 0,
                        totalOrdersCount: response.data.orders.length ?? 0
                    });

                    promotionsToExport.push(pStatForExport);
                }
            }
        }
        
        console.log({promotionsToExport});
        
        exportFilteredPromotionStats(promotionsToExport, this.$i18n);
    }

    resetFilters() {
        this.searchQuery = "";
        document.getElementById('search').value = this.searchQuery;
        this.startAtFilter = new Date();
        this.endAtFilter = new Date();
        this.categoryFilter = this.categories;
    }

    changeSortDateDirection() {
        switch (this.sortByDateDirection) {
            case "ASC":
                this.sortByDateDirection = "DESC";
                break;
            case "DESC":
                this.sortByDateDirection = "ASC";
                break;
            default:
                this.sortByDateDirection = "DESC"
                break;
        }
    }
}
</script>

<style lang="scss">

.promotions-view {
    .button {
        margin-bottom: 15px;
    }

    .duplicate {
        background-color: rgb(137, 212, 255);
        margin-bottom: 5px;
    }
    .duplicate:hover {
        background-color: rgba(137, 212, 255, 0.413);
    }

    .time-filter-container {
        display: flex;
        gap: 15px;
        width: 100%;

        > * {
        flex: 1;
        }
    }
}

</style>