<template>
    <div class="wholesaler-edit-view rf-view background">
        <NavigationBar :title="title">
            <BackButton v-if="canPop" slot="left" @click="pop" />
        </NavigationBar>
        <main>
            <h1>{{ title }}</h1>
            
            <GeneralErrorsView :error-box="errorBox" />

            <SplitForm>
                <div slot="left" class="container">
                    <InputBox :title="$t('editWholesaler.name')" :error-box="errorBox" error-fields="name">
                        <input v-model="name" class="input" :placeholder="$t('editWholesaler.namePlaceholder')">
                    </InputBox>

                    <ColorInput v-model="primaryColor" :title="$t('editWholesaler.primaryColor')" :validator="validator" />
                    <ColorInput v-model="textColor" :title="$t('editWholesaler.textColor')" :validator="validator" />

                    <InputBox :title="$t('fields.email')" :error-box="errorBox" error-fields="email">
                        <input v-model="email" class="input" :placeholder="$t('editWholesaler.emailPlaceholder')">
                    </InputBox>
                    <p class="style-description">
                        {{ $t('editWholesaler.emailDescription') }}
                    </p>

                    <InputBox :title="$t('fields.language')" :error-box="errorBox" error-fields="language">
                        <select v-model="language" class="input">
                            <option v-for="lang in languages" :key="lang" :value="lang">
                                {{ getLanguageName(lang) }}
                            </option>
                        </select>
                    </InputBox>
                </div>

                <template slot="right">
                    <InputBox :title="$t('fields.example')" :error-box="errorBox" error-fields="example">
                        <div ref="example" class="example">
                            <WholesalerLogo :wholesaler="patchedWholesaler" />
                            <PromotionBox :promotion="demoPromotion" />
                        </div>
                    </InputBox>
                    <p class="style-description image-text">
                        {{ $t('editWholesaler.logoDescription') }}
                    </p>

                    <UploadImageButton v-model="logo" :resolutions="resolutions">
                        <div type="button" class="button secundary">
                            {{ $t('editWholesaler.uploadLogoButton') }}
                        </div>
                    </UploadImageButton>
                </template>
            </SplitForm>
        </main>

        <Toolbar>
            <LoadingButton :loading="saving">
                <button class="primary button" @click="save">
                    {{ $t('buttons.save') }}
                </button>
            </LoadingButton>
        </Toolbar>
    </div>
</template>


<script lang="ts">
import { BackButton, ColorInput, InputBox, List, ListItem, LoadingButton, NavigationBar, PromotionBox, SplitForm, Toolbar, UploadImageButton, WholesalerLogo, WholesalerTheme } from "@restofrit/components"
import { Image,Language,LanguageHelper,Promotion, PromotionSettings, ResolutionFit, ResolutionRequest, TranslatedString, Version, WholesalerColors, WholesalerPrivate, WholesalerPrivateSettings, WholesalerSettings } from "@restofrit/structures";
import { AutoEncoderPatchType, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        ColorInput,
        SplitForm,
        UploadImageButton,
        PromotionBox,
        WholesalerLogo
    },
})
export default class WholesalerEditView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    wholesaler!: WholesalerPrivate

    patch: AutoEncoderPatchType<WholesalerPrivate> = WholesalerPrivate.patch({})

    @Prop({ required: true })
    callback: (patch: AutoEncoderPatchType<WholesalerPrivate>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    get title() {
        if (this.isNew) {
            return this.$t('editWholesaler.createNewTitle').toString()
        }
        return this.patchedWholesaler.settings.name
    }

    get languages() {
        return Object.values(Language)
    }

    get isNew() {
        return this.wholesaler.settings.name.length == 0
    }

    get patchedWholesaler() {
        return this.wholesaler.patch(this.patch)
    }

    patchSettings(patch: AutoEncoderPatchType<WholesalerSettings>) {
        this.patch = this.patch.patch({
            settings: patch
        })
        this.updateTheme()
    }

    patchPrivateSettings(patch: AutoEncoderPatchType<WholesalerPrivateSettings>) {
        this.patch = this.patch.patch({
            privateSettings: patch
        })
    }

    get demoPromotion() {
        return Promotion.create({
            settings: PromotionSettings.create({
                title: new TranslatedString({
                    nl: "Demo promotie",
                    fr: "Exemple de promotion"
                }),
                subtitle: new TranslatedString({
                    nl: "Ondertitel",
                    fr: "Sous-titre"
                }),
                promotionText: new TranslatedString({
                    nl: "3 + 1 gratis",
                    fr: "3 + 1 gratuit"
                }),
                promotionSubtext: new TranslatedString({
                    nl: "Ondertitel",
                    fr: "Sous-titre"
                })
            })
        })
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.wholesaler, { version: Version })
    }

    mounted() {
        this.updateTheme()
    }

    updateTheme() {
        WholesalerTheme.loadColors(this.patchedWholesaler, this.$refs.example as HTMLElement)
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }

    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            await this.callback(this.patch)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    // Patch methods
    get name() {
        return this.patchedWholesaler.settings.name
    }

    set name(name: string) {
        this.patchSettings(WholesalerSettings.patch({
            name
        }))
    }

    get language() {
        return this.patchedWholesaler.settings.language
    }

    set language(language: Language) {
        this.patchSettings(WholesalerSettings.patch({
            language
        }))
    }

    getLanguageName(language: Language) {
        return LanguageHelper.getName(language)
    }

    get email() {
        return this.patchedWholesaler.privateSettings.email
    }

    set email(email: string) {
        this.patchPrivateSettings(WholesalerPrivateSettings.patch({
            email
        }))
    }

    get primaryColor() {
        return this.patchedWholesaler.settings.colors.primary
    }

    set primaryColor(primary: string) {
        this.patchSettings(WholesalerSettings.patch({
            colors: WholesalerColors.patch({
                primary
            })
        }))
    }

    get textColor() {
        return this.patchedWholesaler.settings.colors.dark
    }

    set textColor(dark: string) {
        this.patchSettings(WholesalerSettings.patch({
            colors: WholesalerColors.patch({
                dark
            })
        }))
    }

    get logo() {
        return this.patchedWholesaler.settings.logo
    }
 
    set logo(logo: Image | null) {
        this.patchSettings(WholesalerSettings.patch({
            logo
        }))
    }

    get resolutions(): ResolutionRequest[] {
        return [
            ResolutionRequest.create({
                width: 250,
                fit: ResolutionFit.Cover
            }),
            ResolutionRequest.create({
                width: 500,
                fit: ResolutionFit.Cover
            })
        ]
    }
    
}
</script>


<style lang="scss">
@use '~@restofrit/scss/base/variables.scss' as *;

.wholesaler-edit-view {
    .example {
        background: $color-background-shade;
        border-radius: $border-radius;
        padding: 30px 20px;
    }

    .image-text {
        padding-bottom: 15px;
    }
}

</style>