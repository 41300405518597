<template>
    <form class="rf-view forgot-password-view" @submit.prevent="submit">
        <NavigationBar :title="$t('forgotPassword.title')">
            <BackButton v-if="canPop" slot="left" @click="pop" />
            <button v-if="canDismiss" slot="right" class="button icon close gray" type="button" @click="dismiss" />
        </NavigationBar>
        <main>
            <h1>
                {{ $t('forgotPassword.title') }}
            </h1>

            <GeneralErrorsView :error-box="errorBox" />

            <InputBox :title="$t('fields.email')" :error-box="errorBox" error-fields="email">
                <input v-model="email" class="input" :placeholder="$t('fields.placeholders.email')" autocomplete="username" type="email">
            </InputBox>
        </main>

        <FloatingFooter>
            <LoadingButton :loading="loading" class="block">
                <button class="button primary full">
                    {{ $t('forgotPassword.submitButton') }}
                </button>
            </LoadingButton>
        </FloatingFooter>
    </form>
</template>


<script lang="ts">
import { BackButton,FloatingFooter, InputBox, LoadingButton, NavigationBar } from "@restofrit/components"
import { NetworkManager } from "@restofrit/networking";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class ForgotPasswordView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    loading = false

    async submit() {
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            await NetworkManager.server.request({
                method: "POST",
                path: "/auth/forgot-password",
                body: {
                    email: this.email
                },
            })
            this.pop({ force: true })
            alert(this.$t('forgotPassword.confirmationMessage').toString())
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }
    
}
</script>