import { ArrayDecoder, AutoEncoder, BooleanDecoder, DateDecoder, EnumDecoder, field, Identifiable, Patchable, StringDecoder } from '@simonbackx/simple-encoding';
import { v4 as uuidv4 } from "uuid";

import { CustomerCategory, CustomerCategoryOld } from './CustomerCategory';
import { Language } from './Language';
import { User } from './User';
import { Wholesaler } from './Wholesaler';

export enum CustomerStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    Declined = "Declined",
    Blocked = "Blocked"
}

export class CustomerSettings extends AutoEncoder {
    @field({ decoder: new ArrayDecoder(new EnumDecoder(CustomerCategoryOld)) })
    @field({ decoder: new ArrayDecoder(new EnumDecoder(CustomerCategory)), version: 20, upgrade: (o: CustomerCategoryOld[]): CustomerCategory[] => {
        return o.flatMap(c => {
            if (Object.values(CustomerCategory).includes(c as any)) {
                return [c as any as CustomerCategory]
            }
            console.warn("Still found an old category: " + c)
            return []
        })
    } })
    categories: CustomerCategory[] = []

    @field({ decoder: new EnumDecoder(Language), version: 11 })
    language = Language.nl

    @field({ decoder: StringDecoder })
    businessName = ""

    @field({ decoder: StringDecoder })
    VATNumber = ""

    @field({ decoder: StringDecoder })
    contactName = ""

    @field({ decoder: StringDecoder })
    contactPhone = ""

    @field({ decoder: StringDecoder, optional: true, version: 14 })
    city = ""

    @field({ decoder: StringDecoder, optional: true, version: 25 })
    postalCode = ""

    @field({ decoder: StringDecoder, optional: true, version: 25 })
    address = ""

    @field({ decoder: StringDecoder, optional: true, version: 25 })
    internalClientName = ""

    @field({ decoder: StringDecoder, version: 7 })
    number = ""

    @field({ decoder: new EnumDecoder(CustomerStatus) })
    status = CustomerStatus.Pending

    @field({ decoder: BooleanDecoder, version: 16 })
    notificationsEnabled = true

    @field({ decoder: BooleanDecoder, version: 23 })
    isTester = false
}

export enum DeviceTokenType {
    "iOS" = "iOS",
    "Android" = "Android"
}

export class DeviceToken extends AutoEncoder {
    /**
     * Contains the registration id
     */
    @field({ decoder: StringDecoder })
    id: string

    @field({ decoder: new EnumDecoder(DeviceTokenType) })
    type = DeviceTokenType.iOS
}

export class CustomerServerSettings extends AutoEncoder {
    @field({ decoder: new ArrayDecoder(DeviceToken), version: 9 })
    deviceTokens: DeviceToken[] = []
}

/**
 * Customer data that is visible for the customer
 */
export class Customer extends AutoEncoder {
    @field({ decoder: StringDecoder })
    id: string

    @field({ decoder: CustomerSettings })
    settings = CustomerSettings.create({})

    @field({ decoder: Wholesaler })
    wholesaler: Wholesaler
}

/**
 * Customer data that is visible for dashboard users
 */
export class CustomerPrivate extends AutoEncoder {
    @field({ decoder: StringDecoder, defaultValue: () => uuidv4() })
    id: string

    @field({ decoder: CustomerSettings })
    settings = CustomerSettings.create({})

    @field({ decoder: User, version: 8 })
    user: User

    @field({ decoder: StringDecoder, version: 13 })
    wholesalerId: string

    @field({ decoder: DateDecoder, version: 15 })
    createdAt: Date = new Date()
}

/**
 * Customer data that is visible for the customer
 */
export class CreateCustomer extends AutoEncoder {
    @field({ decoder: CustomerSettings })
    settings = CustomerSettings.create({})

    @field({ decoder: Wholesaler })
    wholesaler: Wholesaler

    getId(): IDCreateCustomer {
        return IDCreateCustomer.create({
            settings: this.settings,
            wholesalerId: this.wholesaler.id
        })
    }
}


/**
 * Customer data that is visible for the customer
 */
export class IDCreateCustomer extends AutoEncoder {
    @field({ decoder: CustomerSettings })
    settings = CustomerSettings.create({})

    @field({ decoder: StringDecoder })
    wholesalerId: string
}
