import { ArrayDecoder, AutoEncoder, BooleanDecoder, DateDecoder, field, IntegerDecoder, StringDecoder } from '@simonbackx/simple-encoding';

import { CustomerPrivate } from './Customer';
import { Promotion } from './Promotion';


export class OrderSettings extends AutoEncoder {
    @field({ decoder: Promotion })
    promotion: Promotion

    @field({ decoder: IntegerDecoder })
    amount = 1

    @field({ decoder: BooleanDecoder, optional: true })
    orderToAllMyClients = false
}

export class Order extends AutoEncoder {
    @field({ decoder: StringDecoder })
    id: string

    @field({ decoder: OrderSettings })
    settings: OrderSettings

    @field({ decoder: DateDecoder })
    createdAt: Date
}

export class OrderPrivate extends Order {
    @field({ decoder: CustomerPrivate })
    customer: CustomerPrivate
}

// Fix for reference loop
export class PromotionCustomer extends Promotion {
    @field({ decoder: new ArrayDecoder(Order), version: 17 })
    orders: Order[] = []
}