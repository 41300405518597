<template>
    <div class="rf-list">
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class List extends Vue {
}
</script>

<style lang="scss">

.rf-list {
    padding: 0;
    margin: 0 calc(-1 * var(--rf-horizontal-padding, 40px));
}
</style>