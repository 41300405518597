/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Data, Decoder, Encodeable, EncodeContext, EnumDecoder, ObjectData, Patchable, PatchableDecoder, PatchType, StringDecoder } from "@simonbackx/simple-encoding";
import { SimpleError } from "@simonbackx/simple-errors";

export enum Language {
    nl = "nl",
    fr = "fr",
    en = "en",
}

export class LanguageHelper {
    static getName(language: Language): string {
        switch(language) {
            case Language.nl: return "Nederlands"
            case Language.fr: return "Français"
            case Language.en: return "English"
        }
    }
}

export class TranslatedStringPatch implements Encodeable, Patchable<TranslatedStringPatch> {
    translations: { [key in Language]?: string | null }

    constructor(translations: { [key in Language]?: string|null }) {
        this.translations = translations
    }

    patch(patch: TranslatedStringPatch): this {
        
        const c = new TranslatedStringPatch(Object.assign({}, this.translations)) as this
        for (const key in patch.translations) {
            if (Object.prototype.hasOwnProperty.call(patch.translations, key)) {
                const value = patch.translations[key] as string | null;
                c.translations[key] = value
            }
        }
        return c
    }

    encode(context: EncodeContext) {
        if (context.version <= 14) {
            // Don't add English translation
            const val = {...this.translations, _isPatch: true }
            val[Language.en] = undefined
            return val
        }
        return {...this.translations, _isPatch: true }
    }
}

export class TranslatedString implements Encodeable, Patchable<TranslatedStringPatch> {
    translations: { [key in Language]?: string }

    constructor(translations: { [key in Language]?: string }) {
        this.translations = translations
    }

    append(str: string | TranslatedString): TranslatedString {
        const patch = new TranslatedStringPatch({})
        for (const key in this.translations) {
            if (Object.prototype.hasOwnProperty.call(this.translations, key)) {
                const value = this.translations[key] as string;
                if (typeof str === "string") {
                    patch.translations[key] = value + str
                } else {
                    patch.translations[key] = value + str.get(key as Language)
                }
            }
        }

        return this.patch(patch);
    }

    merge(other: TranslatedString): TranslatedString {
        const patch = new TranslatedStringPatch({})
        for (const key in other.translations) {
            if (Object.prototype.hasOwnProperty.call(other.translations, key)) {
                const value = other.translations[key] as string;
                if (value.length > 0) {
                    patch.translations[key] = value
                }
            }
        }

        return this.patch(patch);
    }

    patch(patch: TranslatedStringPatch): this {
        
        const c = new TranslatedString(Object.assign({}, this.translations)) as this
        for (const key in patch.translations) {
            if (Object.prototype.hasOwnProperty.call(patch.translations, key)) {
                const value = patch.translations[key] as string|null;
                if (value === null) {
                    delete c.translations[key]
                } else {
                    c.translations[key] = value
                }
            }
        }

        return c
    }
    
    static createPatch(str: string | null, language: Language): PatchType<TranslatedString> {
        return new TranslatedStringPatch({ [language]: str })
    }

    getIfExists(language: Language | {locale: string}): string | undefined {
        if (typeof language !== "string") {
            return this.getI18nIfExists(language)
        }
        if (this.translations[language] !== undefined) {
            return this.translations[language] 
        }
        return undefined
    }

    get(language: Language | {locale: string}): string {
        if (typeof language !== "string") {
            return this.getI18n(language)
        }
        if (this.translations[language] !== undefined) {
            return this.translations[language] ?? ""
        }

        const keys = Object.keys(this.translations)
        if (keys.length == 0) {
            return ""
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.translations[keys[0]]
    }

    getI18nIfExists(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2);
        if (!(Object.values(Language) as string[]).includes(lang)) {
            lang = Language.nl
        }
        return this.getIfExists(lang as Language)
    }

    getI18n(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2);
        if (!(Object.values(Language) as string[]).includes(lang)) {
            lang = Language.nl
        }
        return this.get(lang as Language)
    }

    encode(context: EncodeContext) {
        if (context.version <= 14) {
            // Don't add English translation
            const val = {...this.translations }
            val[Language.en] = undefined
            return val
        }
        return this.translations
    }
}

export class TranslatedStringPatchDecoderStatic implements Decoder<PatchType<TranslatedString>> {
    decode(data: Data): PatchType<TranslatedString> {
        if (typeof data.value != "object") {
            throw new SimpleError({
                code: "invalid_field",
                message: "Expected an object",
                field: data.currentField
            })
        }

        const dictionary: PatchType<TranslatedString> = new TranslatedStringPatch({})
        for (const key in data.value) {
            if (Object.prototype.hasOwnProperty.call(data.value, key)) {
                if (key == "_isPatch") {
                    continue
                }
                const language = new EnumDecoder(Language).decode(new ObjectData(key, data.context, data.addToCurrentField(key)))
                const str = data.field(key).nullable(StringDecoder)
                dictionary.translations[language] = str
            }
        }

        return dictionary;
    }

    patchType(){
        return TranslatedStringPatchDecoder;
    }

    patchIdentifier(): Decoder<string | number>{
        throw new Error('A translated string can not be used inside a PatchableArray');
    }
}

export const TranslatedStringPatchDecoder = new TranslatedStringPatchDecoderStatic();

export class TranslatedStringDecoderStatic implements Decoder<TranslatedString> {

    patchType() {
        return TranslatedStringPatchDecoder
    }

    patchIdentifier(): Decoder<string | number>{
        throw new Error('A translated string can not be used inside a PatchableArray');
    }

    decode(data: Data): TranslatedString {
        if (typeof data.value != "object") {
            throw new SimpleError({
                code: "invalid_field",
                message: "Expected an object",
                field: data.currentField
            })
        }

        const dictionary: {[key in Language]?: string} = {}
        for (const key in data.value) {
            // eslint-disable-next-line no-prototype-builtins
            if (Object.prototype.hasOwnProperty.call(data.value, key)) {
                const language = new EnumDecoder(Language).decode(new ObjectData(key, data.context, data.addToCurrentField(key)))
                const str = data.field(key).string
                dictionary[language] = str
            }
        }

        return new TranslatedString(dictionary);
    }
}
export const TranslatedStringDecoder = new TranslatedStringDecoderStatic();