import { CustomerPrivate, CustomerStatus, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities"
import VueI18n from "vue-i18n";
import * as XLSX from "xlsx";

function createCustomerSheet(customers: CustomerPrivate[], wholesalers: WholesalerPrivate[], customerOrders: any[], i18n: VueI18n, includeWholesaler = true) {
    /* make worksheet */
    const data: any[][] = [
        [
            i18n.t("promotion.statistics.columns.wholesaler"),
            i18n.t("promotion.statistics.columns.internalClientName"),
            i18n.t("promotion.statistics.columns.businessName"),
            i18n.t("promotion.statistics.columns.contactName"),
            i18n.t("promotion.statistics.columns.email"),
            i18n.t("promotion.statistics.columns.phone"),
            i18n.t("fields.city"),
            i18n.t("fields.postalCode"),
            i18n.t("fields.address"),
            i18n.t("promotion.statistics.columns.customerNumber"),
            i18n.t("promotion.statistics.columns.VATNumber"),
            i18n.t("promotion.statistics.columns.status"),
            i18n.t("promotion.statistics.numberOfOrders"),
            i18n.t("promotion.statistics.columns.orderAmount"),
        ],
    ];


    for (const customer of customers) {
        const wholesaler = wholesalers.find(w => w.id === customer.wholesalerId)
        if (!wholesaler) {
            throw new Error("Wholesaler not found")
        }
        
        var status: any;
        switch (customer.settings.status) {
            case CustomerStatus.Accepted:
                status = i18n.t("customers.acceptedTitle").toString();
                break;
            case CustomerStatus.Pending:
                status = i18n.t("customers.toAcceptTitle").toString();
                break;
            case CustomerStatus.Blocked:
                status = i18n.t("customers.blocked").toString();
                break;
            case CustomerStatus.Declined:
                status = i18n.t("customers.blocked").toString();
                break;
            default:
                status = "";
                break;
        }

        const actualCustomerOrder = customerOrders.find(co => co.customer.id === customer.id);
        const orders = actualCustomerOrder ? actualCustomerOrder.orders : [];
        const orderAmount = Array.isArray(orders) ? orders.reduce((sum, order) => sum + order.settings.amount, 0) : 0;

        data.push([
            wholesaler.settings.name,
            customer.settings.internalClientName,
            customer.settings.businessName,
            customer.settings.contactName,
            customer.user.email,
            customer.settings.contactPhone,
            customer.settings.city,
            customer.settings.postalCode,
            customer.settings.address,
            customer.settings.number,
            customer.settings.VATNumber,
            status,
            orders.length ?? 0,
            orderAmount
        ]);
    }

    // Delete first column if only one wholesaler
    if (!includeWholesaler) { 
        for (const row of data) {
            row.splice(0, 1)
        } 
    }

    // Delete empty columns
    for (let index = data[0].length - 1; index >= 0; index--) {
        let empty = true
        for (const row of data.slice(1)) {
            if (row[index].length == 0 || row[index] == "/") {
                continue;
            }
            empty = false
            break
        }
        if (empty) {
            for (const row of data) {
                row.splice(index, 1)
            } 
        }
    }

    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column width
    ws['!cols'] = []
    for (const column of data[0]) {
        if (column.toLowerCase().includes("naam")) {
            ws['!cols'].push({width: 25});
        } else if (column.toLowerCase().includes("mail")) {
            ws['!cols'].push({width: 30});
        } else {
            ws['!cols'].push({width: 20});
        }
    }

    return ws

}

export function exportCustomerStats(customers: CustomerPrivate[], wholesalers: WholesalerPrivate[], customerOrders: any[], i18n: VueI18n, includeWholesaler: boolean) {
    const wb = XLSX.utils.book_new();

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(
        wb,
        createCustomerSheet(customers, wholesalers, customerOrders, i18n, includeWholesaler),
        i18n.t("promotion.statistics.customerSheetName").toString()
    );
    XLSX.writeFile(
        wb,
        Formatter.slug(
            i18n.t("promotion.statistics.excelFileName").toString()
        ) + ".xlsx"
    );
}