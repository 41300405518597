<template>
    <div class="rf-view background">
        <NavigationBar :title="title">
            <BackButton v-if="canPop" slot="left" @click="pop" />
        </NavigationBar>
        <main>
            <h1>{{ title }}</h1>
            
            <GeneralErrorsView :error-box="errorBox" />

            <InputBox :title="$t('fields.email')" :error-box="errorBox" error-fields="email">
                <input v-model="email" class="input" :placeholder="$t('fields.placeholders.emailAccount')" type="email">
            </InputBox>

            <Checkbox v-model="isAdmin">
                {{ $t('admins.mainAdmin') }}
            </Checkbox>

            <Checkbox v-model="isTester">
                {{ $t('admins.tester') }}
            </Checkbox>

            <Checkbox v-if="!isNew" :checked="password !== undefined" @change="$event ? (password = '') : (password = undefined)">
                {{ $t('admins.setNewPassword') }}
            </Checkbox>

            <InputBox v-if="isNew || password !== undefined" :title="$t('fields.password')" :error-box="errorBox" error-fields="password">
                <input v-model="password" class="input" :placeholder="$t('fields.placeholders.setPassword')">
            </InputBox>

            <template v-if="!isAdmin">
                <hr>
                <h2>{{ $t('wholesalers.title') }}</h2>
                <p>{{ $t('admins.wholesalersNote') }}</p>

                <List>
                    <ListItem v-for="wholesaler in wholesalers" :key="wholesaler.id" :selectable="true" element-name="label">
                        <Checkbox slot="left" :checked="hasWholesaler(wholesaler)" @change="setWholesaler(wholesaler, $event)" />
                        {{ wholesaler.settings.name }}
                    </ListItem>
                </List>
            </template>
        </main>

        <Toolbar>
            <button v-if="!isNew" class="destructive button" @click="deleteMe">
                {{ $t('buttons.delete') }}
            </button>
            <LoadingButton :loading="saving">
                <button class="primary button" @click="save">
                    {{ $t('buttons.save') }}
                </button>
            </LoadingButton>
        </Toolbar>
    </div>
</template>


<script lang="ts">
import { BackButton, Checkbox, ColorInput, InputBox, List, ListItem, LoadingButton, NavigationBar, SplitForm,Toolbar } from "@restofrit/components"
import { UserPermissions, UserPrivate, Version, WholesalerPrivate } from "@restofrit/structures";
import { AutoEncoderPatchType, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        ColorInput,
        Checkbox,
        SplitForm
    },
})
export default class AdminEditView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    admin!: UserPrivate

    @Prop({ required: true })
    wholesalers!: WholesalerPrivate[]

    patch: AutoEncoderPatchType<UserPrivate> = UserPrivate.patch({})

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<UserPrivate>) => Promise<void>

    @Prop({ required: true })
    isNew!: boolean

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    get title() {
        if (this.isNew) {
            return this.$t('editAdmin.createNewTitle').toString()
        }
        return this.$t('editAdmin.editTitle').toString()
    }

    get patchedAdmin() {
        return this.admin.patch(this.patch)
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.admin, { version: Version })
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }

    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            this.patch.id = this.admin.id
            const arr: PatchableArrayAutoEncoder<UserPrivate> = new PatchableArray()
            if (this.isNew) {
                arr.addPut(this.admin.patch(this.patch))
            } else {
                arr.addPatch(this.patch)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    async deleteMe() {
        if (this.saving) {
            return
        }
        if (!confirm(this.$t('admins.confirmDelete').toString())) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<UserPrivate> = new PatchableArray()
            if (!this.isNew) {
                arr.addDelete(this.admin.id)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    get email() {
        return this.patchedAdmin.email
    }

    set email(email: string) {
        this.patch = this.patch.patch({
            email
        })
    }

    get isAdmin() {
        return this.patchedAdmin.permissions?.isAdmin ?? false
    }

    set isAdmin(isAdmin: boolean) {
        this.patch = this.patch.patch({
            permissions: UserPermissions.create({
                isAdmin,
                isTester: this.isTester,
                wholesalerIds: this.patchedAdmin.permissions?.wholesalerIds ?? []
            })
        })
    }

    get isTester() {
        return this.patchedAdmin.permissions?.isTester ?? false
    }

    set isTester(isTester: boolean) {
        this.patch = this.patch.patch({
            permissions: UserPermissions.create({
                isAdmin: this.isAdmin,
                isTester,
                wholesalerIds: this.patchedAdmin.permissions?.wholesalerIds ?? []
            })
        })
    }

    get password() {
        return this.patchedAdmin.setPassword
    }

    set password(setPassword: string | undefined) {
        if (setPassword !== undefined) {
            this.patch = this.patch.patch({
                setPassword
            })
        } else {
            const p = this.patch.patch({})
            p.setPassword = undefined
            this.patch = p
        }
    }

    hasWholesaler(wholesaler: WholesalerPrivate) {
        return this.patchedAdmin.permissions?.wholesalerIds?.includes(wholesaler.id) ?? false
    }

    setWholesaler(wholesaler: WholesalerPrivate, value: boolean) {
        const ids = (this.patchedAdmin.permissions?.wholesalerIds ?? []).filter(id => id !== wholesaler.id)
        if (value) {
            ids.push(wholesaler.id)
        }
        this.patch = this.patch.patch({
            permissions: UserPermissions.create({
                isAdmin: this.isAdmin,
                wholesalerIds: ids
            })
        })
    }
}
</script>