<template>
    <form class="rf-view reset-forgot-password-view" @submit.prevent="submit">
        <NavigationBar>
            <BackButton v-if="canPop" slot="left" @click="pop" />
            <button v-if="canDismiss" slot="right" class="button icon close gray" type="button" @click="dismiss" />
        </NavigationBar>
        <main>
            <h1>
                Wachtwoord opnieuw instellen
            </h1>

            <GeneralErrorsView :error-box="errorBox" />

            <InputBox title="E-mailadres" :error-box="errorBox" error-fields="email">
                <input v-model="email" class="input" placeholder="" autocomplete="username" type="email" disabled>
            </InputBox>

            <InputBox title="Wachtwoord" :error-box="errorBox" error-fields="password">
                <input v-model="password" class="input" placeholder="Vul jouw wachtwoord hier in" autocomplete="new-password" type="password">
            </InputBox>

            <InputBox title="Wachtwoord herhalen" :error-box="errorBox" error-fields="passwordRepeat">
                <input v-model="repeatPassword" class="input" placeholder="Herhaal je wachtwoord" autocomplete="new-password" type="password">
            </InputBox>
        </main>

        <FloatingFooter>
            <LoadingButton :loading="loading || loadingToken" class="block">
                <button class="button primary full">
                    Wachtwoord wijzigen
                </button>
            </LoadingButton>
        </FloatingFooter>
    </form>
</template>


<script lang="ts">
import { BackButton,FloatingFooter, InputBox, LoadingButton, NavigationBar } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { Token, User } from "@restofrit/structures";
import { Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { SimpleError } from "@simonbackx/simple-errors";
import { HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class ResetForgotPasswordView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    password = ""
    repeatPassword = ""
    loading = false

    user: User | null = null
    loadingToken = false
    temporarySession = new Session()

    @Prop({ default: null })
    token: string | null

    mounted() {
        this.loadToken()
    }

    loadToken() {
        this.loadingToken = true

        const token = this.token ?? new URL(window.location.href).searchParams.get('token');
        if (token) {
            this.loadingToken = true;

            this.temporarySession.server.request({
                method: "POST",
                path: "/auth/token",
                body: {
                    grant_type: "password_token",
                    token: token
                },
                decoder: Token
            }).then(response => {
                // Create new session to prevent signing in
                return this.temporarySession.setToken(response.data)
            }).then(() => {
                const user = this.temporarySession.user!
                this.email = user.email
                this.user = user
                this.loadingToken = false;
            }).catch(e => {
                console.error(e)
                alert("Deze link is ongeldig of vervallen")
                this.dismiss({ force: true })
            })
        } else {
            alert("Deze link is ongeldig of vervallen")
            this.dismiss({ force: true })
        }
    }

    async submit() {
        if (this.loadingToken || this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            if (this.password != this.repeatPassword) {
                throw new SimpleError({
                    code: "invalid_field",
                    message: "De ingevoerde wachtwoorden komen niet overeen",
                    field: "repeatPassword"
                })
            }
            if (this.password.length < 8) {
                throw new SimpleError({
                    code: "invalid_field",
                    message: "Jouw wachtwoord moet minstens 8 karakters lang zijn",
                    field: "password"
                })
            }
            const response = await this.temporarySession.authenticatedServer.request({
                method: "PATCH",
                path: "/auth/user",
                body: {
                    password: this.password
                },
                decoder: User as Decoder<User>
            })
            this.temporarySession.user = response.data
            this.temporarySession.isPublic = true
            Session.shared.copyFrom(this.temporarySession)
            this.dismiss({force: true })
            HistoryManager.setUrl("/")
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }
    
}
</script>