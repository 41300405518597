import { ArrayDecoder, AutoEncoder, AutoEncoderPatchType, BooleanDecoder, DateDecoder, EnumDecoder, field, IntegerDecoder, MapDecoder, PatchType, StringDecoder } from '@simonbackx/simple-encoding';
import { v4 as uuidv4 } from "uuid";

import { CustomerCategory, CustomerCategoryOld } from './CustomerCategory';
import { Image } from './files/Image';
import { Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch } from './Language';

export class PromotionButton extends AutoEncoder {
    @field({ decoder: StringDecoder })
    @field({
        decoder: TranslatedStringDecoder,
        version: 12,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                // Migrate old string values to Dutch
                [Language.nl]: str
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.get(Language.nl)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.nl]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name.translations[Language.nl]) {
                return name.translations[Language.nl] ?? ""
            }
            return undefined
        }
    })
    text = new TranslatedString({})

    @field({ decoder: StringDecoder })
    @field({
        decoder: TranslatedStringDecoder,
        version: 12,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                // Migrate old string values to Dutch
                [Language.nl]: str
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.get(Language.nl)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.nl]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name.translations[Language.nl]) {
                return name.translations[Language.nl] ?? ""
            }
            return undefined
        }
    })
    url = new TranslatedString({})
}

export class Notification extends AutoEncoder {
    @field({ decoder: StringDecoder, defaultValue: () => uuidv4() })
    id: string;

    @field({ decoder: DateDecoder })
    date = new Date

    @field({ decoder: BooleanDecoder })
    didSend = false

    @field({ decoder: TranslatedStringDecoder })
    title = new TranslatedString({})

    @field({ decoder: TranslatedStringDecoder })
    description = new TranslatedString({})

    @field({ decoder: BooleanDecoder, version: 25 })
    ifNotRead = true

    usedNotificationTitle(promotion: PromotionSettings): TranslatedString {
        // Construct the default and merge with the overrides
        return promotion.title.append(": ").append(promotion.promotionText).merge(this.title)
    }

    usedNotificationDescription(promotion: PromotionSettings): TranslatedString {
        return promotion.subtitle.merge(this.description)
    }
}

export class PromotionSettings extends AutoEncoder {
  @field({ decoder: StringDecoder })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string): TranslatedString => {
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str,
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  title = new TranslatedString({});

  @field({ decoder: StringDecoder })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string): TranslatedString => {
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str,
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  subtitle = new TranslatedString({});

  @field({ decoder: StringDecoder })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string): TranslatedString => {
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str,
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  promotionText = new TranslatedString({});

  @field({ decoder: StringDecoder })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string): TranslatedString => {
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str,
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  promotionSubtext = new TranslatedString({});

  @field({ decoder: BooleanDecoder, version: 2 })
  promotionSubtextStrikethrough = false;

  @field({ decoder: StringDecoder })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string): TranslatedString => {
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str,
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  description = new TranslatedString({});

  @field({ decoder: new ArrayDecoder(new EnumDecoder(CustomerCategoryOld)) })
  @field({
    decoder: new ArrayDecoder(new EnumDecoder(CustomerCategory)),
    version: 20,
    upgrade: (o: CustomerCategoryOld[]): CustomerCategory[] => {
      return o.flatMap((c) => {
        if (Object.values(CustomerCategory).includes(c as any)) {
          return [c as any as CustomerCategory];
        }
        console.warn("Still found an old category: " + c);
        return [];
      });
    },
  })
  categories: CustomerCategory[] = [];

  @field({ decoder: BooleanDecoder, version: 3 })
  enableOrders = true;

  @field({ decoder: BooleanDecoder, optional: true, version: 25 })
  canBeCombined = false;

  @field({ decoder: StringDecoder, optional: true, version: 25 })
  internalDescription = "";

  @field({ decoder: BooleanDecoder, optional: true, version: 25 })
  requireDeliveryAddress = false;

  @field({ decoder: IntegerDecoder, version: 3, nullable: true }) // product amount per order
  minimumAmount: number | null = null;

  @field({ decoder: IntegerDecoder, version: 3, nullable: true }) // product amount per client
  maximumAmount: number | null = null;

  @field({
    decoder: IntegerDecoder,
    optional: true,
    version: 25,
    nullable: true,
  }) // total product amount per promotion
  totalAmount: number | null = null;

  @field({ decoder: PromotionButton, version: 3, nullable: true })
  button: PromotionButton | null = null;

  @field({ decoder: BooleanDecoder, version: 3, field: "containPicture" })
  @field({ decoder: BooleanDecoder, version: 4 })
  containImage = false;

  @field({ decoder: Image, nullable: true, version: 4 })
  @field({
    decoder: new MapDecoder(StringDecoder, Image),
    version: 26,
    upgrade: (oldImage: Image | null): Map<string, Image> => {
      const map = new Map<string, Image>();
      if (oldImage) {
        // Migrate old image to all languages
        Object.values(Language).forEach((lang) => {
          map.set(lang, oldImage);
        });
      }
      return map;
    },
    downgrade: (images: Map<string, Image>): Image | null => {
      // Try Dutch first, then fall back to first available image
      return images.get(Language.nl) ?? images.values().next().value ?? null;
    }
  })
  image: Map<string, Image> = new Map<string, Image>();

  /**
   * @deprecated
   */
  @field({
    decoder: StringDecoder,
    nullable: true,
    version: 10,
    upgrade: () => null,
  })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string | null): TranslatedString => {
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str ?? "",
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  notificationTitle = new TranslatedString({});

  /**
   * @deprecated
   */
  @field({
    decoder: StringDecoder,
    nullable: true,
    version: 10,
    upgrade: () => null,
  })
  @field({
    decoder: TranslatedStringDecoder,
    version: 12,
    upgrade: (str: string | null): TranslatedString => {
      if (str !== null && typeof str !== "string") {
        throw new Error(str);
      }
      return new TranslatedString({
        // Migrate old string values to Dutch
        [Language.nl]: str ?? "",
      });
    },
    downgrade: (name: TranslatedString): string => {
      return name.get(Language.nl);
    },
    upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
      if (!str) {
        return new TranslatedStringPatch({});
      }
      return new TranslatedStringPatch({ [Language.nl]: str });
    },
    downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
      if (name.translations[Language.nl]) {
        return name.translations[Language.nl] ?? "";
      }
      return undefined;
    },
  })
  notificationDescription = new TranslatedString({});

  @field({ decoder: StringDecoder, version: 19 })
  number = "";

  @field({ decoder: new ArrayDecoder(Notification), version: 24 })
  notifications: Notification[] = [];

  getImageForLanguage(language: Language | string): Image | null {
    // Try requested language first
    let image = this.image.get(language);
    if (image) {
      return image;
    }

    // Fall back to Dutch if available
    image = this.image.get(Language.nl);
    if (image) {
      return image;
    }

    // Fall back to first available image
    const firstImage = this.image.values().next().value;
    return firstImage ?? null;
  }
}

export enum PromotionStatus {
    Disabled = "Disabled",
    Active = "Active",
    Testing = "Testing"
}

export class PromotionStatusHelper {
    static getName(category: PromotionStatus, i18n: { locale: string; t: (key: string) => string }): string {
        switch(category) {
            case PromotionStatus.Disabled: return i18n.t('status.disabled')
            case PromotionStatus.Active: return i18n.t('status.active')
            case PromotionStatus.Testing: return i18n.t('status.testing')
        }
    }
}

export class Promotion extends AutoEncoder {
    @field({ decoder: StringDecoder, defaultValue: () => uuidv4() })
    id: string;

    @field({ decoder: PromotionSettings })
    settings = PromotionSettings.create({});

    @field({
        decoder: new MapDecoder(StringDecoder, PromotionSettings.patchType()),
        version: 18,
    })
    wholesalerOverrides = new Map<
        string,
        AutoEncoderPatchType<PromotionSettings>
    >();

    @field({ decoder: new ArrayDecoder(StringDecoder), optional: true })
    excludeWholesalers: string[] = [];

    @field({ decoder: StringDecoder, nullable: true })
    wholesalerId: string | null = null;

    @field({ decoder: DateDecoder })
    // default value: today at 10:00
    startAt: Date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        10, 0, 0
    );

    @field({ decoder: DateDecoder })
    // default value: today at 23:59
    endAt: Date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23, 59, 0
    );

    /**
     * Date of the next notification (if any)
     */
    @field({ decoder: DateDecoder, nullable: true })
    notificationAt: Date | null = null;

    /**
     * @deprecated
     */
    @field({ decoder: BooleanDecoder })
    didSendNotification = false;

    @field({ decoder: new EnumDecoder(PromotionStatus), version: 21 })
    status = PromotionStatus.Active;

    getSettingsFor(wholesalerId: string | null = null): PromotionSettings {
        if (!wholesalerId) {
            return this.settings;
        }

        const overrides = this.wholesalerOverrides.get(wholesalerId);
        if (overrides) {
            return this.settings.patch(overrides);
        }
        return this.settings;
    }
}