<template>
    <ComponentWithPropertiesInstance v-if="loggedIn" :key="root.key" :component="root" />
    <LoadingView v-else-if="hasToken" key="loading-view" />
    <ComponentWithPropertiesInstance v-else :key="loginRoot.key" :component="loginRoot" />
</template>

<script lang="ts">
import { Session } from "@restofrit/networking"
import { ComponentWithProperties, ComponentWithPropertiesInstance } from "@simonbackx/vue-app-navigation";
import { Component, Prop, Vue } from "vue-property-decorator";

import LoadingView from "./LoadingView.vue"
import { switchLanguage } from "@restofrit/i18n";

@Component({
    components: {
        ComponentWithPropertiesInstance,
        LoadingView
    },
})
export default class AuthenticatedView extends Vue {
    @Prop()
    root: ComponentWithProperties

    @Prop()
    loginRoot: ComponentWithProperties

    loggedIn = false
    hasToken = false

    created() {
        this.changed();
        Session.shared.addListener(this, this.changed.bind(this));
    }

    destroyed() {
        Session.shared.removeListener(this);
    }

    changed() {
        this.loggedIn = Session.shared.isComplete()
        this.hasToken = Session.shared.hasToken()

        if (Session.shared.user?.language) {
            switchLanguage(Session.shared.user?.language)
        }

        console.log("Authenticated view changed: "+this.loggedIn)
    }
}
</script>